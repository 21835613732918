#CardsViewController ion-toolbar{
    --border-width: 0 !important;
    /*--background: whitesmoke;*/
    /*background-color: whitesmoke;*/
}
#cards-slider-warpper{
    background-color: whitesmoke;
    height: 100%;
}

#cards-slider-warpper .islider-outer > li{
    padding: 20px 12px;

    padding-bottom: calc(constant(safe-area-inset-bottom) + 20px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
}


.study-card{
    border-radius: 12px;
    overflow: hidden;
    box-shadow: none;
    /*box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.2);*/
    max-width: 480px;
    min-height: 85%;
    width: 100% !important;
}

.study-card .btn{
    position: relative;
    border: none;
    border-radius: 30px !important;
    padding: 10px;
    font-size: 15px;
    transition :all 0.1s ease-out;
}
/*.study-card .btn:hover{*/
/*box-shadow: 0px 0px 0px 0px black, 0px 1px 2px rgba(0, 0, 0, 0.2) inset;*/
/*}*/


.study-card .addition-button{
    position: absolute;
    top: 20px;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.study-card .addition-button .detail-button{

}
.study-card .addition-button .memo-button{
    width: 13px;
    height: 13px;
    background-color: whitesmoke;
    border-radius: 20px;
    display: inline-block;
    color: transparent;
    margin-left: 8px;
    position: relative;
    top: -1px;
}

/*todo: Media Query*/
/*.study-card .key{*/
    /*position: relative;*/
/*}*/
/*.study-card .key:after{*/
    /*content: '×';*/
    /*position: absolute;*/
    /*right: 10px;*/
    /*top: 50%;*/
    /*transform: translateY(-52%);*/
    /*padding: 4px 8px;*/
    /*border-radius: 3px;*/
    /*color: rgba(0, 0, 0, 0.5);*/
    /*border: 1px solid rgba(0, 0, 0, 0.1);*/
    /*border-top-color: rgba(0, 0, 0, 0.02);*/
    /*border-left-color: rgba(0, 0, 0, 0.04);*/
    /*border-right-color: rgba(0, 0, 0, 0.2);*/
    /*border-bottom: none;*/
    /*box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.4);*/
    /*font-size: 10px;*/
    /*transition: all 0.1s linear;*/
/*}*/
/*.study-card .key:hover:after,*/
/*.study-card .key.hovering:after{*/
    /*box-shadow: 0px 0.2px 0px rgba(0, 0, 0, 0.4);*/
/*}*/

/*.study-card .key-space:after{*/
    /*content: "SPACE" !important;*/
/*}*/
/*.study-card .key-enter:after{*/
    /*content: "ENTER" !important;*/
/*}*/
/*.study-card .key-next:after{*/
    /*content: "►" !important;*/
/*}*/
/*.study-card .key-a:after{*/
    /*content: "A";*/
/*}*/
/*.study-card .key-b:after{*/
    /*content: "B";*/
/*}*/
/*.study-card .key-c:after{*/
    /*content: "C";*/
/*}*/
/*.study-card .key-d:after{*/
    /*content: "D";*/
/*}*/
/*.study-card .key-1:after{*/
    /*content: "1";*/
/*}*/
/*.study-card .key-2:after{*/
    /*content: "2";*/
/*}*/
/*.study-card .key-3:after{*/
    /*content: "3";*/
/*}*/
/*.study-card .key-4:after{*/
    /*content: "4";*/
/*}*/

.study-card .btn-success{
    background-image: linear-gradient(to right, #43e98d 0%, #2fd892 100%);
    color: white;
}

.study-card .btn-light{
    background-color: whitesmoke;
}

/*progress*/
.IonRangeWrapper{
    position: absolute;
    top: -10px;
    left: -10px;
    right: -15px;
}

ion-range{
    padding: 0px 35px 0px 20px;
    margin-top: -10px;
}
ion-range::part(knob){
    width: 5px;
    height: 5px;
    top: 27px;
    border-radius: 20px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 23px;
    background-color: transparent;
}
/*ion-range::part(knob):after{*/
    /*content: "·";*/
    /*font-size: 16px;*/
    /*color: #ffffff7d;*/
    /*font-weight: 800;*/
/*}*/
ion-range::part(bar){
    background-color: #e9ecef;
    height: 5px;
    width: auto;
    right: -20px;
    /*border-radius: 5px;*/
}
ion-range::part(bar-active){
    height: 5px;
    margin-left: -10px;
    border-radius: 0px 5px 5px 0px;
    box-shadow: 0px 3px 5px 0px rgba(55, 210, 153, 0.3);
    background-color: #39ce74;
    /*transition :all 0.2s ease-out;*/
    z-index: 1;
}


.progress{
    display: none;
}
/*.progress{*/
    /*position: absolute;*/
    /*top: 8.5px;*/
    /*left: 10px;*/
    /*right: 10px;*/
    /*height: 4px;*/
    /*border-radius: 5px;*/
    /*overflow: visible;*/
/*}*/
/*.progress-bar{*/
    /*width: 0%;*/
    /*border-radius: 5px;*/
    /*box-shadow: 0px 3px 5px 0px rgba(55, 210, 153, 0.3);*/
    /*z-index: 1;*/
    /*background-color: #39ce74;*/
    /*transition :all 0.2s ease-out;*/
/*}*/