#curve_wrapper{
    position: relative;

    /*border-radius: 15px !important;*/
    /*margin: 10px;*/
    /*margin-bottom: 50px;*/
    /*overflow: visible;*/

    /*background-image: linear-gradient(to bottom, #ffffff 0%, whitesmoke 100%);*/
    /*box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.3);*/
    /*overflow: hidden;*/
}

#curve_wrapper .sections{

}

#curve_wrapper section{
    position: relative;
    /*background-color: white;*/
}

#curve_wrapper #charts-container{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 15px 15px 0px 0px;
    min-height: 300px;
}
#curve_wrapper #charts-container canvas{
    pointer-events: painted !important;
}

#curve_wrapper #expand_container_button{
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: whitesmoke;
    padding: 5px 12px;
    border-radius: 30px;
    font-size: 12px;
    color: #3e3e3e;
}
#curve_wrapper #expand_container_button ion-icon{
    font-size: 14px;
    position: relative;
    top: 2px;
    margin-right: 5px;
    pointer-events: none;
}


#curve_wrapper #handlr_wrappper{
    position: absolute;
    top: -10px;
    left: 0px;
    right: 0px;
    height: 35px;
    /*background-color: whitesmoke;*/
    display: none;
}
#curve_wrapper .handlr{
    position: absolute;
    bottom: 12px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 5px;
    border-radius: 10px;
    background-color: #8c8c8c;
    opacity: 0.3;
}
#curve_wrapper .handlr.active{
    opacity: 1 !important;
}


/*section*/
#curve_wrapper section{
    padding: 15px 16px;
    margin-bottom: 10px;
}
#curve_wrapper section:last-child{
    margin-bottom: 0px;
}
#curve_wrapper section .title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
#curve_wrapper section .sub_title{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
    opacity: 0.3;
}

#curve_wrapper section .row{
    display: flex;
}
#curve_wrapper section .row .col-6{
    width: 50%;
}
#curve_wrapper section .row .col-4{
    width: 33.3%;
}
#curve_wrapper section .row .col-3{
    width: 25%;
}

#curve_wrapper section .row .col .items{
    margin-right: 16px;
}
#curve_wrapper section .row .col:last-child .items{
    margin-right: 0px;
}

#curve_wrapper section .item{
    position: relative;
    font-size: 14px;
    padding: 10px 2px;
    border-top: 1px solid whitesmoke;
}

@media screen and (max-width:320px){
    section .item{
        font-size: 10px;
    }
    section .item.active:after{
        width: 22px !important;
        height: 22px !important;
    }
}

#curve_wrapper section .item:after{
    content: '';
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    /*background-image: url('assets/icons/icon/standard_arrow_right.png');*/
    background-size: contain;
    opacity: 0.2;
}
#curve_wrapper section .item.active:after{
    opacity: 0.75;
    background-image: none;
    background-color: transparent;
    /*background-image: url(assets/icons/icon/check.png);*/
    background-size: cover;
    border-radius: 20px;
    width: 25px;
    height: 25px;
    right: 6px;
    margin-right: -8px;
}

#curve_wrapper section .action{
    text-align: center;
    font-size: 15px;
}

/*section.viewport*/
#curve_wrapper #viewport_filter{
    padding-left: 0px;
    padding-right: 0px;
}
#curve_wrapper #viewport_filter .row .col .items{
    margin-right: 0px !important;
}

/*section-card*/
#curve_wrapper #container_card{
    position: relative;
    height: 145px;
    overflow: hidden;
    /*测试*/
    /*background-color: red;*/
}

#curve_wrapper #clear_filter{
    position: relative;
}
#curve_wrapper #enter_filter{
    /* position: fixed; */
    /* bottom: 20px; */
    left: 10px;
    /* background-color: #404040; */
    color: #383838;
    /* border-radius: 50px; */
    padding: 15px 2px;
    /* box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 25%); */
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: -10px;
    border-bottom: 1px solid whitesmoke;
}
#curve_wrapper #enter_filter.empty{
    display: none;
}
#curve_wrapper #enter_filter ion-icon{
    font-size: 19px;
}

#curve_wrapper #enter_filter .action{
    font-size: 14px !important;
}

#curve_wrapper #clear_filter{
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 12px;
    right: 14px;
    background-color: whitesmoke;
}
#curve_wrapper .section-card{
    /*box-shadow: 0px 25px 40px -10px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 10px;*/
}

#curve_wrapper .section .actions_panel{
    height: 30px;
    background-color: white;
    border-radius: 30px;
    position: absolute;
    top: -5px;
    right: -2px;
    padding: 0px 6px 0px;
}
#curve_wrapper .section .actions_panel img{
    height: 100%;
}
#curve_wrapper .section .actions_panel{
    opacity: 0;
    pointer-events: none;
}
#curve_wrapper .section .button-text{
    line-height: 30px;
    padding: 0px 8px;
    color: #2b2b2b;
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
}


#curve_wrapper .background_secondary{
    background-color: rgba(0, 0, 0, 0.05);
}

/*统计图*/
#curve_wrapper #chart_wrapper{
    position: relative;
    overflow: hidden;
}

#curve_wrapper #chart_wrapper #rose_div{
    float: left;
    width: 150px;
    height: 150px;
    border-radius: 45px;
    margin-right: 0px;
    position: relative;
    background-color: transparent;
}
#curve_wrapper #chart_wrapper .infos_div{
    position: absolute;
    left: 0px;
    right: 0px;
    padding-left: 150px;
}
#curve_wrapper #chart_wrapper .infos_div .info{
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 8px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 10px;
    color: #555555;
    display: inline-block;
}
#curve_wrapper #chart_wrapper .infos_div .info:last-child{
    display: block;
    background-color: transparent;
    padding: 2px 0px;
}
#curve_wrapper #chart_wrapper .info.total{
    position: absolute;
    left: 68px;
    transform: translate(-50%, -50%);
    top: 50%;
    background: transparent;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    color: #828282;
}

#curve_wrapper #chart_wrapper .infos_div .info .sub_info{
    position: relative;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 7px 10px 7px 30px;
    border-radius: 5px;
    display: block;
    margin-bottom: 4px;
    color: #555555;
}
#curve_wrapper .sub_info:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: gray;
    transform: translateY(-50%);
}
#curve_wrapper .sub_info img{
    width: 22px;
    opacity: 0.3;
    position: absolute;
    right: 6px;
    top: 48%;
    transform: translateY(-50%);
}
/*#chart_wrapper .infos_div .info .sub_info.memo_untracked:after{*/
/*background-color: #D1D6DE;*/
/*}*/
/*#chart_wrapper .infos_div .info .sub_info.memo_start:after{*/
/*background-color: #54C0FF;*/
/*}*/
/*#chart_wrapper .infos_div .info .sub_info.memo_known:after{*/
/*background-color: #20D3DA;*/
/*}*/
/*#chart_wrapper .infos_div .info .sub_info.memo_familiar:after{*/
/*background-color: #37df9c;*/
/*}*/
/*#chart_wrapper .infos_div .info .sub_info.memo_danger:after{*/
/*background-color: #ff5a30;*/
/*}*/
#curve_wrapper .memo_danger:after{
    background-color: transparent;
}

/*items*/
#curve_wrapper #wordlists_div{
    margin-bottom: -10px;
}
#curve_wrapper .items_div{

}
#curve_wrapper .items_div .item{
    position: relative;
    height: 46px;
    line-height: 46px;
    border-top: 1px solid whitesmoke;
    font-size: 16px;
    font-weight: 400;
    color: #555555;
    margin: 0px 10px;
    margin-left: 4px;
}
#curve_wrapper .items_div img{
    width: 22px;
    opacity: 0.3;
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
}
#curve_wrapper .items_div{

}

#curve_wrapper .item .num{
    padding: 4px 10px;
    background-color: whitesmoke;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #7b7b7b;
}

/*today_info*/
#curve_wrapper #today_info{
    padding: 10px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 200;
    line-height: 1.8em;
    font-size: 11px;
    margin-top: -10px;
}
@media screen and (max-width:320px){
    #chart_wrapper .infos_div .info .sub_info{
        font-size: 9px;
    }
    #chart_wrapper .infos_div{

    }
}


/*UISegmentedControl*/
#selection_filter{
    display: none !important;
}
#curve_wrapper .UISegmentedControl{
    border-radius: 100px;
    background-color: whitesmoke;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 12px;
    margin-left: 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.02) inset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#curve_wrapper .UISegmentedControl .item{
    background-color: transparent;
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    padding: 6px;
    border: none;
}
#curve_wrapper .UISegmentedControl .item:after{
    display: none !important;
}
#curve_wrapper .UISegmentedControl .item.active{
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/*total*/
#selection_total{
    display: none !important;
}