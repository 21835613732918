.page-tools ion-content{
    background-color: whitesmoke;
    --background:whitesmoke;
}

/*general*/
.page-tools ion-icon{
    font-size: 20px;
    opacity: 0.8;
}
.page-tools .section{
    margin-bottom: 0px;
}

/*section-user*/
.section-user{
    margin-top: -20px !important;
    margin-bottom: 0px !important;
}
.user-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*section-cells*/
.section-cells .section-content{
    padding: 0px 5px;
}
.page-tools ion-row{
    border-bottom: 1px solid whitesmoke;
}
.page-tools ion-row:last-child{
    border-bottom: none;
}
.page-tools ion-col > div{
    position: relative;
    /* background-color: whitesmoke; */
    border-radius: 10px;
    /* padding: 20px; */
    /* font-size: 16px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

/*section-cells section-content-clear*/
.section-cells .section-content-clear{
    background-color: transparent;
}

.cell-language .flag{
    width: 28px;
    height: 20px;
    background-color: whitesmoke;
    background-image: url("../assets/flags/all.png");
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: 2px solid whitesmoke;
    text-align: center;
    margin-right: -5px;
    transform: scale(0.9);
}
.cell-language[language="en"] .flag{
    background-image: url("../assets/flags/en.svg");
}
.cell-language[language="fr"] .flag{
    background-image: url("../assets/flags/fr.svg");
}
.cell-language[language="it"] .flag{
    background-image: url("../assets/flags/it.svg");
}

.cell-language .voice-language{
    display: none;
}
.cell-language[language="all"] .voice-language{
    display: flex;
}



/*section-cols*/
.section-cols ion-grid{
    padding: 0px;
    margin: 0px -5px;
}
.section-cols .section-content-clear ion-col > div{
    background-color: white;
    padding: 20px 20px;
}


/*🍉page elements*/
#input-source-file{
    display: none;
}

.voice-language{
    font-size: 12px;
    color: grey;
    align-items: center !important;
    justify-content: center !important;
    border-top: 1px solid whitesmoke;
    border-radius: 0px !important;
    margin-top: 7px;
    padding-top: 10px !important;
}
.voice-language ion-icon{
    font-size: 15px;
    margin-left: 5px;
}

.cell-clear-memo ion-icon{
    font-size: 18px;
}