.ui-tableview{
    width: 100%;
}
.ui-tableview-wrapper{
    /*overflow-x: scroll;*/
}

/*header*/
.ui-tableview-header{
    position: relative;
}
.ui-tableview-header .ui-tableview-actions-wrapper{
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-60%);
}
.ui-tableview-header .ui-tableview-action{
    font-size: 12px;
    color: #5f5f5f;
}

/*actions*/
.ui-tableview .ui-tableview-cell .actions-wrapper{
    position: absolute;
    top: -12px;
    left: 2px;
    z-index: 10;
}
.ui-tableview .ui-tableview-cell .cell-action{
    font-size: 10px;
    opacity: 0.9;
}
.ui-tableview .ui-tableview-cell .cell-action:hover{
    opacity: 1;
}

/*cells*/
.ui-tableview-cell{
    position: relative;
    border-bottom: 1px solid whitesmoke;
    padding: 6px 10px;
}
.ui-tableview-cell:last-child{
    border-bottom: none;
}
.ui-tableview-cell.edited{
    background-color: #d0eeff;

}
.ui-tableview-cell.header{
    background-color: whitesmoke;
}

.ui-tableview-cell-item{
    position: relative;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
    overflow: visible;
}

.ui-tableview-cell-item .content{
    position: relative;
    height: 20px;
    max-width: 20em;
    /*省略号*/
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.ui-tableview-cell-item:focus{
    outline: none;
    background-color:#f6fff0;
}
.ui-tableview-cell-item.has-options:after{
    content: '▼';
    position: absolute;
    right: 5px;
    transform: scaleY(0.7);
    opacity: 0.2;
    top: 5px;
}
.ui-tableview-cell-item.is-rich:after{
    content: '▶︎';
    position: absolute;
    top: 7px;
    right: 8px;
    font-weight: 800;
    color: #d3d3d4;
    font-size: 10px;
}
.ui-tableview-cell.body .ui-tableview-cell-item:before{
    content: '';
    position: absolute;
    right: 2px;
    width: 1px;
    background-color: whitesmoke;
    transform: translateY(-50%);
    top: 50%;
    height: 60%;
}
.ui-tableview-cell.body .ui-tableview-cell-item:last-child:before,
.ui-tableview-cell.body .ui-tableview-cell-item:focus:before
{
    display: none;
}
.ui-tableview-cell .ui-tableview-cell-item[key='id']{
    display: none;
}
.ui-tableview-cell .ui-tableview-cell-item[key='unico_id']{
    color: transparent;
}
.ui-tableview-cell.body .ui-tableview-cell-item[key='unico_id']{
    font-weight: 600;
    color: #007bfe;
}

.ui-tableview-cell.body .ui-tableview-cell-item .icon{
    width: 20px;
    height: 20px;
    background-size: cover;
    background-color: whitesmoke;
}
.ui-tableview-cell.body .ui-tableview-cell-item .icon.empty{
    border-radius: 20px;
}
.ui-tableview-cell.body .ui-tableview-cell-item .url{
    /*color: #00bf82;*/
    color: #cecece;
    font-weight: unset;
    text-decoration: none !important;
    display: block;
    text-align: left;
}
.ui-tableview-cell.body .ui-tableview-cell-item .url i{
    background-color: #007bfe;
    color: white;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    right: 0px;
    font-size: 10px;
    transform: scale(0.8);
    opacity: 0.75;

}
.ui-tableview-cell.body .ui-tableview-cell-item .url i:before{
    display: none;
}
.ui-tableview-cell.body .ui-tableview-cell-item .url i:after{
    content: '打开';
}
.ui-tableview-cell.body .ui-tableview-cell-item .url i:hover{
    opacity: 1;
}

.ui-tableview .true{
    color: #00bf82;
}
.ui-tableview .false{
    color: #ff5413;
}
.ui-tableview .error{
    color: #ffce1d;
}

.ui-tableview .cell-action.btn-danger{
    background-color: #ff5413;
    border-color: transparent;
    font-weight: 800;
}

.ui-tableview .dropdown-menu{
    font-size: 12px;
    min-width: unset;
}
.ui-tableview .dropdown-item{
    padding: 5px 10px;
}
.ui-tableview .dropdown-menu,
.ui-tableview .dropdown-menu *{
    user-select: none;
}