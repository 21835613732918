.settings-panel-wrapper{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    /*background-color: rgba(0, 0, 0, 0.3);*/
    z-index: 1000;
}
/*🍋SettingsPanel*/
#settings-panel{
    position: absolute;
    top: 55px;
    right: 10px;
    width: 90%;
    max-height: 96%;
    max-width: 320px;
    overflow-y: scroll;
    transform: scale(0.8);
    transform-origin: right top;
}

#settings-panel .hidden{
    display: none !important;
}

/*section*/
#settings-panel .section{
    padding: 0px;
    margin: 20px 10px 20px 18px;
}
#settings-panel .section > .title{
    font-size: 10px;
    font-weight: 600;
    margin-left: 2px;
    margin-bottom: 6px;
    color: #ffffff;
    background-color: #292929;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 6px 6px 2px 2px;
}

/*row*/
#settings-panel .row{
    position: relative;
    overflow: visible;
    margin: 0px -10px;
    padding: 0px 10px;
}
#settings-panel .row > .action{
    text-align: center;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

/*card*/
#settings-panel .card{
    position: relative;
    background-color: transparent;
    border: none;
    padding: 10px 2px;
    /* margin-bottom: 10px; */
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
}
#settings-panel .card i{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
}
#settings-panel .card:hover i{
    opacity: 0.8;
}
/*#settings-panel .card.active:after{*/
/*content: '当前模式';*/
/*position: absolute;*/
/*top: 9px;*/
/*background-color: rgba(21, 230, 163, 0.9);*/
/*color: #272727;*/
/*padding: 0px 7px;*/
/*right: 7px;*/
/*border-radius: 15px;*/
/*font-size: 10px;*/
/*font-weight: 600;*/
/*}*/

#settings-panel .card-half{
    display:inline-block;
    box-sizing: border-box;
    width: 48.9%;
    margin-right: 1%;
    float: left;
}
#settings-panel .card-half:last-child{
    margin-right: 0px;
}
#settings-panel .card>.title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
#settings-panel .card>.title>a{
    font-size: 10px;
    color: #bfbfbf;
    border-radius: 10px;
}
#settings-panel .card>.description{
    font-size: 13px;
    opacity: 0.5;
    white-space: normal;
    /*height: 28px;*/
    /*margin-bottom: -14px;*/
    /*overflow: hidden;*/
}
#settings-panel .action{
    color: #5f5f5f;
    font-size: 12px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px 8px;
    border-radius: 10px;
}


/*square*/
#settings-panel .squares-stack{
    overflow: hidden;
    display: flex;
    justify-content: center;
}

#settings-panel .square{
    position: relative;
    float: left;
    margin-right: 10px;
    padding: 8px;
    border-radius: 8px;
}
#settings-panel .square:last-child{
    margin-right: 0px;
}
#settings-panel .square > .title{
    font-size: 10px;
    text-align: center;
    margin-top: -5px;
    margin-bottom: 10px;
    color: #1ad699;
}
#settings-panel .square img{
    width: 60px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
}

/*icon*/
#settings-panel .icon{
    position: absolute;
    left: 12px;
    width: 50px;
    padding: 0px;
    top: 20px;
    display: none;
}
@media screen and (min-width:520px){
    #settings-panel .icon{
        top: 50%;
        transform: translateY(-50%);
    }
}
#mode_spell_word .icon,
#mode_pair_normal .icon{
    top: 50%;
    transform: translateY(-50%);
}

#settings-panel .icon>.title{
    padding: 3px 5px;
    border-radius: 3px;
    background-color: #00e2a3;
    color: white;
    margin-bottom: 2px;
    font-size: 10px;
    text-align: center;
}
#settings-panel .icon>.title-long{
    font-size: 8px;
    line-height: 1.5em;
}

#settings-panel .textfield{
    padding: 2px 5px;
    background-color: rgba(65, 227, 167, 0.4);
    border-radius: 10px;
    color: rgb(40, 160, 116);
    font-size: 10px;
}
#settings-panel .textfield span{

}

#settings-panel .bar{
    width: 100%;
    height: 6px;
    border-radius: 10px;
    background-color: #00e2a3;
    margin-bottom: 2px;
}
#settings-panel .bar:last-child{
    margin-bottom: 0px;
}
#settings-panel .bar.fade{
    opacity: 0.45;
}

#settings-panel .bar-y-large{
    border-radius: 3px;
    height: 20px;
}
#settings-panel .bar-y-middle{
    height: 8px;
}
#settings-panel .bar-y-short{

}
#settings-panel .bar-x-large{
    width: 100%;
}
#settings-panel .bar-x-middle{
    width: 75%;
}
#settings-panel .bar-x-short{
    width: 50%;
}
#settings-panel .bar-color-green{
    background-color: #74F0BA;
}
#settings-panel .bar-color-blue{
    background-color: #74E0F0;
}
#settings-panel .bar-color-red{
    background-color: #FFC6A8;
}

#settings-panel .bars-stack{
    overflow: hidden;
}
#settings-panel .bars-stack .bar{
    float: left;
    width: 35%;
}
#settings-panel .bars-stack .bar:first-child{
    margin-right: 8%;
}

/*label*/
#settings-panel .label{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    opacity: 0.25;
}
#settings-panel .switch{
    position: absolute;
    top: 0px;
    right: 12px;
}

/*active*/
#settings-panel .card .active{
    display: inline-block;
    position: absolute;
    right: 9px;
    top: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: #2a2a2a;
    color: transparent;
    border-radius: 30px;
    text-align: center;
    font-size: 10px;
    background-image: url("../../../assets/icons/cards-setting/check-white.png");
    background-size: cover;
    z-index: 1;
    border: 3px solid;
    transform: translateY(-50%);
}

#settings-panel .square{
    opacity: 0.25;
}
#settings-panel .square.active{
    opacity: 1;
}

#settings-panel #mode_pair_normal .icon{
    transform: scale(0.6) translateY(-80%) translateX(-20%);
    width: 75px;
}