.TTSPreloaderContext{
    display: none !important;
}

/*visible*/
.TTSPreloaderContext.visible{
    display: block !important;
    position: relative;
    z-index: 100;
}
.TTSPreloaderContext.visible #audios-wrapper{
    display: block !important;
}

/*cells interface*/
.word-cell{
    padding: 5px 0px;
    border-top: 1px solid whitesmoke;
    cursor: pointer;
}