/*🍋sweetalert*/
body.swal2-height-auto{
    height: inherit !important;
    min-height: 100%;
}
.swal2-popup{
    padding: 35px 20px !important;
    border-radius: 18px;
}
.swal2-title{
    margin-top: 16px;
}
.swal2-content{
    opacity: 1 !important;
    font-size: 13px !important;
    line-height: 2em;
}
.swal2-validation-message{
    border-radius: 40px;
    margin-left: 50px !important;
    margin-right: 50px !important;
}
.swal2-styled.swal2-cancel{
    display: inline-block;
    background-color: whitesmoke !important;
    color: #3c3c3c !important;
}

button.swal2-styled{
    background-image: none !important;
    box-shadow: none !important;
}

/*sweertalert.actions*/
.alert-actions{
    padding-top: 10px !important;
    padding-bottom: 20px !important;
}
.alert-actions .message{
    font-size: 15px;
    margin-bottom: 25px;
}

.alert-actions .alert-button{
    position: relative;
    background-color: whitesmoke;
    color: #525858;
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.alert-actions .alert-button .check{
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    font-family: system-ui, sans-serif !important;
    font-weight: 600 !important;
}

.alert-actions .swal2-content{
    opacity: 1;
}
.alert-actions .swal2-content,
.alert-actions .swal2-actions{
    padding: 0px;
}
.alert-actions .swal2-actions{
    margin-top: 10px;
}
.alert-actions .swal2-cancel{
    width:100%;
    margin: 0px;
    font-size: 16px;
    color: rgb(120, 120, 120) !important;
    background-color: transparent !important;
}


/*🍋sweetalert.mobile*/
/*todo: css media query*/
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation{
    /*background: none !important;*/
}
.swal2-popup{
    box-shadow: 0 12px 73px rgba(0, 0, 0, 0.38);
}
.swal2-header{
    font-size: 12px;
}

/*🍋inline-mode*/
.swal2-container.inline-mode{
    background-color: transparent !important;
    pointer-events: none !important;
}
.swal2-container.inline-mode .swal2-popup{
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 20px !important;
    border-radius: 20px;
    width: auto;
    height: 35px;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);
    opacity: 1;

    position: absolute;
    top: 20px;
    top: calc(constant(safe-area-inset-top) + 20px);
    top: calc(env(safe-area-inset-top) + 20px);

    left: 50%;
    transform: translateX(-50%);
}
.swal2-container.inline-mode .swal2-header{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}
.swal2-container.inline-mode .swal2-header h2{
    margin: 0px;
    font-size: 13px;
    font-weight: 400;
}
.swal2-container.inline-mode .swal2-content{
    display: none;
}
.swal2-container.inline-mode .swal2-actions.swal2-loading{
    margin: 0px;
    margin-left: -20px;
    margin-right: -5px;
    display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 8px;
    transform: scale(0.75);
}