*{
    user-select: none !important;
    cursor: pointer;
    -webkit-user-select: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-overflow-scrolling:touch;
}
body {
    margin: 0px;
    padding: 0px;
    background-color: whitesmoke;
    font:14px/1.5 Lato, sans-serif;
}

#MdxFileManager h4{
    position: relative;

    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 5px;
    font-size: 12px;
    color: #0a0a0a;
    border-radius: 2px;
    font-weight: 600;
}

#MdxFileManager #dicts-menu{
    position: absolute;top: 0px;right: 10px;z-index: 100;
}
#MdxFileManager #dicts-menu .btn{
    border-radius: 20px;
}

#MdxFileManager ul{
    list-style: none;
    padding: 0px;
}
#MdxFileManager li{
    height: 60px;
    line-height: unset;
    cursor: pointer;
}

#MdxFileManager .dragging{
    z-index: 100;
}

#MdxFileManager .item{
    border: none !important;
    padding: 15px 16px !important;
    height: auto !important;
    color: #6b6b6b;
    font-size: 12px;
}
#MdxFileManager .item{
    position: relative;
    padding: 20px 15px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
/*.item.main{*/
/*box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1) inset;*/
/*border-radius: 2px;*/
/*overflow: hidden;*/
/*}*/

#MdxFileManager .item.main:after{
    content: "main";
    position: absolute;
    top: -80px;
    left: 0px;
    background-color: #3a9bff;
    color: transparent;
    width: 0px;
    height: 80px;
    z-index: 0;
}
#MdxFileManager .item.main .name{
    position: relative;
    /*color: white;*/
    /*font-weight: 600;*/
    z-index: 1;
}
#MdxFileManager .item.main .for{
    pointer-events: none;
    opacity: 0;
}

#MdxFileManager .item .name{
    display: block;
    overflow: hidden;
    line-height: 1.5em;
    font-size: 13px;
    color: #7b7b7b;
    font-weight: 600;
    margin-right: 200px;
}
#MdxFileManager .osx .item span:after{
    content: '▼';
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
    opacity: 0.2;
    transform: scaleY(0.7) translateY(-1px);
}

#MdxFileManager .item .for{
    position: absolute;
    top: 50%;
    right: 65px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
}
#MdxFileManager .item .for.active{
    color: #545454;
}
#MdxFileManager .item .for.active:after{
    content: '';
    position: absolute;
    display: inline-block;
    top: 50%;
    right: -12px;
    background-color: #00da75;
    height: 8px;
    border-radius: 10px;
    font-size: 8px;
    color: white;
    width: 8px;
    text-align: center;
    font-weight: 800;
    transform: translateY(-50%);
}

#MdxFileManager .item .for_mainpart:before{
    content: '未启用';
}
#MdxFileManager .item .for_mainpart.active:before{
    content: '已启用';
}


#MdxFileManager .item .for_index{
    right: 140px;
    opacity: 0;
}


#MdxFileManager .item_empty{
    font-size: 12px;
    border: 1px dashed whitesmoke !important;
    border-radius: 2px;
}
#MdxFileManager .item_empty .handlr{
    opacity: 0;
}
#MdxFileManager .handlr,
#MdxFileManager .download,
#MdxFileManager .error{
    position: absolute;
    top: 50%;
    right: 0px;
    line-height: 45px;
    width: 45px;
    padding-right: 12px;
    font-size: 15px;
    text-align: right;
    opacity: 0.5;
    transform: translateY(-50%);
}
#MdxFileManager .download{
    font-size: 11px;
    opacity: 1;
    right: 8px;
    background-color: #005bff26;
    color: #3d77ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 22px;
    height: 22px;
    border-radius: 30px;
}
#MdxFileManager .error{
    color: #fb5959;
    opacity: 1;
}

#MdxFileManager .downloaded{
    font-size: 8px;
    font-weight: 600;
    color: #808080;
    display: inline-block;
    background-color: whitesmoke;
    padding: 2px 6px;
    border-radius: 10px;
    position: relative;
    top: 2px;
    left: -2px;
}

#MdxFileManager .block{
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
}

/*menu*/
#MdxFileManager .item{
    position: relative;
}
#MdxFileManager .menu{
    position: absolute;
    top: 38px;
    width: 150px;
    left: 10px;
    min-height: 40px;
    padding: 10px 0px;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
#MdxFileManager .menu-item{
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    font-weight: 400;
}
#MdxFileManager .menu-item:hover{
    background-color: whitesmoke;
}
#MdxFileManager .menu-item.delete{
    color: #ff5353;
}

#MdxFileManager #draging_mask{
    position: fixed;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    pointer-events: none;
    background-color: rgba(76, 255, 172, 0.4);
}

#MdxFileManager .section_title{
    font-size: 20px;
    font-weight: 800;
    opacity: 0.8;
    margin: 10px 8px;
    margin-bottom: -5px;
}

/*presentation*/
#MdxFileManager .presentation{
    position: relative;
    left: 0px;
    right: 0px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.2);
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-weight: 600;
    z-index: 100;
}

/*🍋Language*/
#MdxFileManager .language_menu .dropdown{
    position: absolute;
    top: 17px;
    right: 15px;
}
#MdxFileManager .language_menu ul{
    min-width: 150px;
}

#MdxFileManager .language_menu .dropdown-toggle{
    display: flex;
    align-items: center;
}

#MdxFileManager .cell{
    overflow: hidden;
    padding: 10px 15px;
    margin-bottom: 5px;
}
#MdxFileManager .cell:last-child{
    margin-bottom: 0px;
}
#MdxFileManager .cell.active{
    border-left: 5px solid #545454;
    background-color: rgba(0, 0, 0, 0.03);
}
#MdxFileManager .cell>*{
    float: left;
}
#MdxFileManager .flag{
    height: 18px;
    margin-right: 8px;
}
#MdxFileManager .dropdown-toggle .flag{
    height: 14px;
    border-radius: 10px;
}
#MdxFileManager .language_label{
    display: inline-block;
    font-family: sans-serif;
    line-height: 18px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 600;
    margin-right: 5px;
}

#MdxFileManager .hidden{
    display: none;
}

#MdxFileManager #speaker-menu-button{
    border-radius: 10px;
    color: #777b80;
}

/*🍋Legacy Search*/
#MdxFileManager #lab_div.disable{
    opacity: 0.3;
    pointer-events: none;
    display: none;
}
#MdxFileManager #legacy_search_cell .switch{
    display: inherit;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 0 0;
}

/*🍋ImagePicker*/
#MdxFileManager .file-picker{
    margin-bottom: -20px;
}

/* 容器 */
#MdxFileManager .file-picker{overflow: hidden;}
#MdxFileManager .file-picker>*:first-child{
    margin-left:0px;
}

/* 拾取器 */
#MdxFileManager .btn-block,
#MdxFileManager .image_box_div{
    position: relative;
    height: 40px;
    cursor: pointer;
    background-color: whitesmoke;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    font-weight: 600;
    color: #404040;
    margin-bottom: 8px;
}
#MdxFileManager .image_box_div:after{
    content: "添加词典文件";
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 40px;
}

/* 正在拾取*/
#MdxFileManager .image_box_div:hover, .image_box_div.Overing{
    border-color: #39b311;
    background-color:#f1ffef;

}
#MdxFileManager .image_box_div:hover:after, .image_box_div.Overing:after{
    color: #39b311;
}


/* 正在上传 */
#MdxFileManager .image_box_div.Uploading{
    border-color: #ff7638;
}
#MdxFileManager .image_box_div.Uploading:after{
    content: "上传中";
    font-size: 8px;
    font-family: sans-serif;
    font-weight:600;
    color: #ff7638;
    line-height:30px;
}

/*🍋Buttons*/
#MdxFileManager .action-button{
    position: absolute;
    right: 0px;
    top: -25px;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
    padding: 5px 8px;
    border-radius: 15px;
    font-weight: 400;
}


/*🍋refine for iOS*/
#MdxFileManager .ios #language_div{
    padding-top: 40px;
}
#MdxFileManager .ios #language_div .language_menu .dropdown{
    top: 47px !important;
}