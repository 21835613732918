.page-discover .page-section{
    margin-bottom: -20px;
}

#section-charts .UISegmentedControl{
    position: relative;
    left: 5px;
}
#section-charts .section-content{
    padding: 0px 10px 0px 0px;
}