#WordsViewController ion-title ion-icon{
    position: relative;
    top: 3px;
    margin-left: 2px;
    color: #ff5542;
}
#WordsViewController ion-content{
    --background: whitesmoke;
}
#WordsViewController .wordstableview{
    background-color: white;
    border-radius: 10px;
}

#WordsViewController .section-tableview{
    margin-bottom: 100px;
}
/*#words-wrapper{*/
    /*padding: 0px !important;*/
/*}*/

