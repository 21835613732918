/*🍋view*/
.words-render-wrapper .text-sandy{
    padding: 20px !important;
}

/*🍋tableview*/
.section-tableview .section-content{
    padding: 0px !important;
}
.ui-tableview-cell{
    position: relative;
    padding: 16px !important;
}
.ui-tableview-cell .word_string{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    opacity: 0.75;
}
.ui-tableview-cell .word_mean{
    font-size: 13px;
    opacity: 0.3;
}
.ui-tableview-cell .word.add{
    margin: 0px;
    text-align: center;
    cursor: pointer;
}
.ui-tableview-cell .word.add .word_string{
    opacity: 1;
    color: #585858;
}

.word .note{
    font-size: 12px;
    opacity: 0.35;
    margin-top: 8px;
    border-top: 1px dashed #d4d4d4;
    padding-top: 5px;
}
.word .memo_wrapper{
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 80px;
    cursor: pointer;
}
.word .action_wrapper{
    position: absolute;
    right: 25px;
    top: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.25;
    pointer-events: none;
}
.word.memo_untracked .action_wrapper{
    right: 14px;
}
.word.memo_start .action_wrapper{
    right: 20px;
}

/*@Depracated: 标记简洁显示*/
/*.word .memo{*/
    /*position: absolute;*/
    /*width: 15px;*/
    /*height: 15px;*/
    /*top: 10px;*/
    /*right: 10px;*/
    /*border-radius: 20px;*/
    /*background-color: whitesmoke;*/
    /*opacity: 0.8;*/
/*}*/
/*.word .repeat_times{*/
    /*display: flex;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*font-size: 9px;*/
    /*color: white;*/
    /*align-items: center;*/
    /*justify-content: center;*/
/*}*/
/*@Depracated: 标记文字显示*/
.word .memo{
    position: absolute;
    height: 14px;

    top: 20px;
    right: 30px;
    border-radius: 20px 3px 3px 20px;
    background-color: whitesmoke;
    opacity: 0.8;
     display: flex;
     align-items: center;
     justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}
.word .repeat_times{
    font-size: 9px;
    color: white;
    width: 14px;
    height: 14px;
    line-height: 15px;
    text-align: center;
    border-radius: 3px 10px 10px 3px;
    background-color: inherit;
    margin-left: 3px;
    margin-right: -3px;
    position: absolute;
    right: -12px;
    top: 0px;
}
.word .memo:before{
    font-size: 9px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.word .memo[title='memo_danger']:before{
    content: '待复习';
}
.word .memo[title='memo_start']:before{
    content: '新词';
}
.word .memo[title='memo_known']:before{
    content: '已学习'; /*已复习*/
}
.word .memo[title='memo_familiar']:before{
    content: '已牢记';
}
.word .memo[title='memo_untracked']:before{
    content: '未学';
}
.word .memo[title='memo_untracked']{
    border-radius: 10px;
    transform: translateX(15px);
}
.word .memo[title='memo_untracked'] .repeat_times{
    display: none;
}


.memo_menu .dropdown-item{
    cursor: pointer;
    text-align: center;
    justify-content: center !important;
}

/*current*/
.ui-tableview-cell.current{
    /*background-color: rgb(235, 245, 255);*/
}
.ui-tableview-cell.current .word_string,
.ui-tableview-cell.current .word_mean{
    color: #0088ff;
}
.ui-tableview-cell.current .word_string{
    opacity: 1;
}

/*🍋filters*/

/*buttons*/
.top-left-buttons-wrapper > .button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    color: #9c9c9c;
    /*background-color: whitesmoke;*/
    position: relative;
    /*top: -8px;*/
}
.top-left-buttons-wrapper > .button.toggle{
    background-color: #ffb2002e;
    color: #ffb200;
}
.top-left-buttons-wrapper > .button.view ion-icon{
    font-size: 20px;
}
.top-left-buttons-wrapper > .button.order i{
    font-size: 17px;
    position: relative;
    top: -1px;
}
.top-left-buttons-wrapper > .button.order ion-icon{
    font-size: 19px;
}
.top-left-buttons-wrapper > .button.filter i{
    font-size: 15px;
}
.top-left-buttons-wrapper > .button.filter ion-icon{
    font-size: 17px;
}
.top-left-buttons-wrapper > .button .label{
    color: black !important;
    font-size: 9px;
    opacity: 0.5;
    position: absolute;
    bottom: -1.8em;
    display: none;
}

/*description*/
.options-description{
    background-color: transparent;
    font-size: 10px;
    padding: 5px 13px;
    color: gray;
    min-height: 25px;
    background-color: #00000005;
}
.options-description.warning{
    background-color: #ffb2001c;
}
.options-description .action{
    color: #ffb200;
    font-size: 11px;
    opacity: 0;
}
.options-description.warning .action{
    opacity: 1;
}

/*options*/
.options-wrapper{
    background-color: #3c424c;
    color: white;
    box-shadow: 0px 4px 5px inset #00000040;
    border-radius: 2px 2px 10px 10px;
}
.options-wrapper .dropdown-menu{
    position: relative;
    padding: 5px 0px;
    margin: 0px;
    border: none;
    background-color: unset;
    top: 0px;
    float: unset;
    backdrop-filter: unset;
}
.options-wrapper .dropdown-item{
    color: white;
    justify-content: center !important;
}
.options-wrapper .dropdown-item.action{
    color: #ffc822 !important;
}
.options-wrapper .dropdown-item.finish{
    border-top: none !important;
    background-color: #ffffff24 !important;
    margin: 10px 10px 5px;
    width: auto;
    border-radius: 6px;
}
.options-wrapper .dropdown-item:hover,
.options-wrapper .dropdown-item:focus{
    background-color: transparent;
    color: white;
}


/*🍋manage*/
.top-buttons-wrapper{
    padding: 15px 8px 10px !important;
    min-height: 60px;
    border-bottom: 1px solid whitesmoke;
}

#manage-toggle-button{
    font-size: 15px;
    color: #ffb200;
    font-weight: 600;
    /* background-color: rgba(255, 188, 0, 0.08); */
    /*padding: 4px 12px;*/
    padding: 4px 10px 4px 12px;
    border-radius: 20px;
    position: relative;
    top: -2px;
    text-align: right;
}
#manage-toggle-button:hover{
    opacity: 0.8;
    /*cursor: pointer;*/
    /*background-color: #ffb100;*/
    /*color: white;*/
}

#selected_num{
    position: absolute;
    background-color: #347fff;
    color: white;
    padding: 3px 10px;
    text-align: center;
    border-radius: 20px;
    bottom: -25px;
    left: 50%;
    font-size: 9px;
    font-weight: 600;
    transform: translateX(-50%);
}
#manage-buttons{
    position: fixed;
    bottom: 30px;
    bottom: calc(constant(safe-area-inset-bottom) + 30px);
    bottom: calc(env(safe-area-inset-bottom) + 30px);

    left: 50%;
    transform: translateX(-50%);
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.35);
    z-index: 100;
}
#manage-buttons.hidden{
    display: none !important;
}
/*#manage-buttons.invalid{*/
    /*opacity: 0;*/
    /*pointer-events: none;*/
/*}*/
#manage-buttons.invalid .normal{
    opacity: 0.1;
    pointer-events: none;
}
.manage-button{
    position: relative;
    cursor: pointer;
    width: 58px;
    height: 58px;
    user-select: none;
}
.manage-button:hover .icon{
    opacity: 0.7;
}
.manage-button.finish{
    background-color: #070707;
    color: white;
    border-radius: 10px;
    transform: scale(0.8);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.manage-button .icon{
    width: 100%;
    transform: scale(0.85);
}

/*words tableview scrollable*/
.wordstableview.selecting,
.ion-page.mode-play .wordstableview{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    background-color: white;
    overflow: hidden;
}
.wordstableview.selecting{
    background-color: whitesmoke;
}
.wordstableview.selecting .top-buttons-wrapper,
.wordstableview.selecting .words-render-wrapper{
    background-color: white;
}
.wordstableview.selecting .words-render-wrapper{
    border-radius: 0px 0px 12px 12px;
    overflow-x: hidden;
}

.wordstableview.selecting .words-render-wrapper,
.ion-page.mode-play .wordstableview .words-render-wrapper{
    overflow-y: scroll;
}


/*🍋selection*/
.wordstableview.selecting .top-left-buttons-wrapper{
    /*opacity: 0;*/
    /*pointer-events: none;*/
}

.wordstableview.selecting .ui-tableview-wrapper{
    padding-bottom: 50px;
}
.wordstableview.selecting .ui-tableview-cell .texts{
    display: inline-block;
    padding-left: 20px !important;
    opacity: 0.2;
}
.wordstableview.selecting .ui-tableview-cell .memo_wrapper{
    pointer-events: none;
}
.wordstableview.selecting .ui-tableview-cell .action_wrapper{
    opacity: 0;
}

.wordstableview .selectable-area{
    display: none;
}

/*selecting 滚动支持*/
.wordstableview.selecting .words-render-wrapper{
    margin-bottom: 50px;
}

.selection-area {
    background: rgba(46, 115, 252, 0.11);
    border-radius: 0.1em;
    border: 2px solid rgba(98, 155, 255, 0.81);
    border-radius: 5px;
}
.selected {
    opacity: 1 !important;
}
.wordstableview.selecting .texts:after{
    content: '✓';
    font-family: system-ui;
    position: absolute;
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    color: gray;
    /*color: white;*/
    /*border: 2px solid transparent;*/
    font-size: 14px;
    font-weight: 800;
    border-radius: 30px;
    top: 30px;
    left: 6px;
}
.wordstableview.selecting .texts.selected:after{
    color: #2173ff;
    /*border: 2px solid #c6dbff;*/
}


.select-helper{
    position: fixed;
    top: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    background-color: whitesmoke;
    padding: 20px 0px;
    border-radius: 20px;
    width: 150px;
}
.select-helper .fas{
    font-size: 50px;
    /*transform: rotate(-90deg);*/
    opacity: 0.75;
    margin-bottom: 10px;
}
.select-helper span{
    font-size: 11px;
    opacity: 0.5;
}
.select-helper a{
    border: none;
    font-size: 13px;
    margin-top: 20px;
    color: #5f5f5f;
    opacity: 1;
}

/*🍋float buttons*/
.buttons-wrapper{
    display: flex;
    background-color: white;
    border-radius: 30px;
    width: 140px;
    justify-content: space-evenly;
    background-color: rgb(235, 245, 255);
    padding: 0px 10px;
}
.buttons-wrapper .button{

}
.buttons-wrapper .button img{
    width: 50px;
    position: relative;
    top: 8px;
    pointer-events: none;
}

/*play*/
.buttons-wrapper .button-play .pause{
    display: none;
}
.buttons-wrapper .button-play.playing .play{
    display: none;
}
.buttons-wrapper .button-play.playing .pause{
    display: block;
}

#WordsViewController .float-button{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.page-study .float-button{
    position: absolute;
    left: 50%;
    top: -75px;
    transform: translateX(-50%);
}


/*🍋player-buttons*/
#player-buttons{
    position: fixed;
    bottom: 0px;
    left: 10px;
    right: 10px;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    /* background-color: #ffffffc4; */
    /* backdrop-filter: blur(10px); */
    border-top: 1px solid #ffffff12;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: none;;
}
#player-buttons .player-button{
    position: relative;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 15px;
    font-weight: 600;
}

#player-buttons .player-button .label{
    position: absolute;
    bottom: -5px;
    left: 0px;
    font-size: 8px;
    font-weight: 400;
    right: 0px;
    text-align: center;
    color:#a5a5a5;
}

#player-buttons .player-button.play{
    background-color: #ffffff40;
    border-radius: 40px;
    transform: scale(0.9);
}

#player-buttons .player-button.play img{
    position: relative;
    top: 7px;
    left: 1px;
    filter: grayscale(1) brightness(5);
}

#player-buttons .player-button.play .pause{
    display: none;
}

#player-buttons.playing .player-button.play .play{
    display: none;
}
#player-buttons.playing .player-button.play .pause{
    display: block;
}

#player-buttons .player-button.language{
    position: relative;
    top: -6px;
}
#player-buttons .player-button.language i{
    font-size: 24px;
}

#player-buttons .player-button.speed{
    position: relative;
    top: -7px;
}
#player-buttons .player-button.speed i{
    font-size: 22px;
}
#player-buttons .player-button.cancel{
    font-size: 22px;
}

#player-guide-button{
    display: none;
    color: white;
    opacity: 0.5;
    margin-right: 10px;
}
#player-guide-button i{
    margin-right: 5px;
}

/*🍉PlayingMode*/

/*show/hide*/
.ion-page.mode-play .section,
.ion-page.mode-play .action_wrapper,
/*.ion-page.mode-play .top-buttons-wrapper,*/
.ion-page.mode-play .float-button{
    display: none !important;
}
.ion-page.mode-play .memo_wrapper{
    pointer-events: none;
    opacity: 0.2;
}

.ion-page.mode-play .section.section-tableview{
    display: block !important;
}
.ion-page.mode-play #player-buttons{
    display: flex;
}

.ion-page.mode-play .top-buttons-wrapper{
    border-bottom: none;
}
.ion-page.mode-play #manage-toggle-button{
    display: none !important;
}
.ion-page.mode-play #player-guide-button{
    display: inline-block !important;
}

/*invalid*/
.ion-page.mode-play .wordstableview.invalid{
    pointer-events: none;
}
.ion-page.mode-play .wordstableview.invalid .player-button.play{
    opacity: 0.5;
}

/*spacing*/
.ion-page.mode-play .wordstableview{
    padding-bottom: 90px;
    border-radius: 0px !important;
    /*padding-bottom: calc(constant(safe-area-inset-bottom) + 85px);*/
    /*padding-bottom: calc(env(safe-area-inset-bottom) + 85px);*/
}

/*color light*/
/*.ion-page.mode-play ion-content{*/
    /*--background: white !important;*/
/*}*/

/*color dark*/
.ion-page.mode-play .wordstableview{
    background-color: #373a42 !important;
}
.ion-page.mode-play .ui-tableview-cell{
    border-bottom: none;
}
.ion-page.mode-play .ui-tableview-cell .texts{
    color: white !important;
}

/*read-langauge*/
.ion-page.mode-play .wordstableview[read-language='zh_en'] .word_string{
    transform: translateY(125%);
}
.ion-page.mode-play .wordstableview[read-language='zh_en'] .word_mean{
    transform: translateY(-100%);
}

.ion-page.mode-play .wordstableview[read-language='en'] .word_mean{
    display: none;
}
.ion-page.mode-play .wordstableview[read-language='zh'] .word_string{
    display: none;
}
.ion-page.mode-play .wordstableview[read-language='zh'] .word_mean{
    font-size: 15px;
    opacity: 1;
}