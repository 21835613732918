.content{
    padding: 16px;
    background-color: whitesmoke;
    min-height: 100%;
}

.invisibile{
    opacity: 0;
}

.content .section-clear{
    position: relative;
}
.content .section-clear > .title{
    font-size: 16px;
    font-weight: 600;
    opacity: 0.9;
}
.content .section-clear > .description{
    font-size: 12px;
    opacity: 0.5;
}
.content .section-clear > .buttons-group{
    position: absolute;
    top: -5px;
    right: 0px;
}

.StudyPlanSettingContent .card{
    position: relative;
    margin: 0px -5px;
    margin-top: 8px;
    margin-bottom: 30px;

    padding: 15px 12px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05);
    background-color: white;
}


.StudyPlanSettingContent .card .cell{
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
}

.StudyPlanSettingContent .card .name{
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    opacity: 0.9;
}

.tabs_wrapper{
    padding: 10px 0px;
    padding-top: 0px;
    margin-top: -10px;
}

.pills{
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.08);
    display: inline-block;
    padding: 3px;
    margin: 5px -3px 10px;
    border-radius: 20px;
}
.pill{
    display: inline-block;
    background-color: transparent;
    border-radius: 30px;
    padding: 2px 10px;
    font-size: 13px;
    color: #4e4e4e;
}
.pill.active{
    background-color: white;
}

.tabs{

}
.tab{
    display: none;
}
.tab.active{
    display: block;
}

.tab .labels_stack{
    position: relative;
    overflow: hidden;
    top: 5px;
}
.tab .label{
    width: 50%;
    float: left;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    opacity: 0.25;
}
.tab .title{
    text-align: center;
    margin: 30px;
}
.tab .picker_div{
    height: 180px;
}


/*options*/
.option-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.option-content span{
    font-size: 12px;
    opacity: 0.5;
    margin: 0px 5px;
}
.option-content span:first-child{
    margin-right: 20px;
}