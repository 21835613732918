body {
  background-color: #372113;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.cf-container {
  box-sizing: content-box;
  width: 300px;
  height: 300px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cf-container .cf-flame-container {
  width: 100px;
  height: 100px;
  bottom: 0px;
  position: absolute;
}
.cf-container .cf-log-container {
  width: 60%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  left: 50px;
}
.cf-container .cf-flame-container#base-fire .cf-flame:nth-child(1) {
  background: #cd4015;
  opacity: 0.95;
}
.cf-container .cf-flame-container#base-fire .cf-flame:nth-child(2) {
  background: #ff5a30;
  width: 100px;
  height: 100px;
  opacity: 0.85;
  left: 75px;
}
.cf-container .cf-flame-container#base-fire .cf-flame:nth-child(3) {
  background: #ff9d37;
  width: 60px;
  height: 60px;
  opacity: 0.85;
  left: 130px;
}
.cf-container .cf-flame-container:nth-child(2) {
  width: 150px;
  height: 150px;
  bottom: 0px;
  position: absolute;
}
.cf-container .cf-flame-container:nth-child(2) .cf-flame {
  width: 75px;
  height: 75px;
  left: 75px;
}

.cf-log {
  background: #723830;
  width: 20px;
  height: 30px;
  transform-origin: center;
  position: absolute;
  bottom: 0px;
  border-radius: 0.5em;
  box-shadow: 0 3px 10px #372113;
  width: 100%;
}
.cf-log:nth-child(1) {
  transform: rotate(15deg);
}
.cf-log:nth-child(2) {
  transform: rotate(-15deg);
}
.cf-flame:nth-child(1) {
  background: #f5ed8f;
}
.cf-flame:nth-child(2) {
  background: #fde239;
}
.cf-flame:nth-child(3) {
  background: #ffdc01;
}
.cf-flame:nth-child(4) {
  background: #fdac01;
}
.cf-flame:nth-child(5) {
  background: #7ca3ff;
}
.cf-flame:nth-child(6) {
  background: #68a5ff;
}
.cf-flame:nth-child(7) {
  background: #4083ff;
}
.cf-flame:last-child {
  background: #76dfc0;
}
.cf-flame {
  background: #37df9c;
  width: 100px;
  height: 100px;
  border-radius: 1em;
  position: absolute;
  bottom: 0px;
  left: 100px;
}

#fireNodes2 .cf-flame {
  -webkit-animation: flameColor 1.5s ease-out infinite;
          animation: flameColor 1.5s ease-out infinite;
}



@keyframes flameColor {
  0% {
    background: #c63509;
  }
  20% {
    background: #cd4015;
  }
  30% {
    background: #fdac01;
  }
  50% {
    background: #ffb401;
  }
  60% {
    background: #fdac01;
  }
  70% {
    background: #ffdc01;
  }
  80% {
    background: #fde239;
  }
  100% {
    background: #f5ed8f;
  }
}