.SearchDetail *{
    cursor: pointer !important;
}
.SearchDetailContent .flex-wrapper{
    position: relative;
    display: flex;
    height: 100%;
    background-color: whitesmoke;
    border-radius: 15px 15px 0px 0px;
    overflow-x: hidden;
}

/*🍉UI设置*/
.SearchDetail[font_family='serif'] *{
    font-family: Serif !important;
}
.SearchDetail[font_family='sans-serif'] *{
    font-family: sans-serif !important;
}
.SearchDetail[font_family='monospace'] *{
    font-family: monospace !important;
}
.SearchDetail[font_family='fantasy'] *{
    font-family: fantasy !important;
}

.SearchDetail[font_weight='200'] *{
    font-weight: 200 !important;
}
.SearchDetail[font_weight='400'] *{
    font-weight: 400 !important;
}
.SearchDetail[font_weight='800'] *{
    font-weight: 800 !important;
}


/*🍉图片*/
.is-loading {
    background-color: black;
    background-image: url("../../assets/loading.gif");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;

    min-width: 35px;
    min-height: 35px;
}
.is-loading img{
    content: '';/*移除ios空白图片的灰色边*/
}

/* 🍉单词显示 */
@font-face {
    font-family: 'Kingsoft Phonetic Plain' ;
    src: url('../../assets/fonts/Kingsoft Phonetic Plain.ttf')
}
@font-face {
    font-family: 'KK' ;
    src: url('../../assets/fonts/kk.ttf')
}

/*🍋通用*/

body,
html{
    margin: 0px;
    padding: 0px;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

html {
    position: relative;
    background-color: white;
    width:100%;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-overflow-scrolling:touch;
}
body{
    margin: 0px;
    padding: 0px;
    padding-bottom: 20px;
}

#app{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.SearchDetailContent .one-dizio{
    line-height:1.8rem;
    word-break: break-word;
    user-select: all !important;
    -webkit-user-select: auto !important;
    /* word-break: break-all; */

    /*iOS不响应.on('click') 参考: https://blog.zfanw.com/ios-safari-click-not-working/ */
    cursor: pointer !important;
}


body .alias{
    font-family: sans-serif;
}
body .alias.en,
body .one-dizio[data-dizio-name="5 Million Dictionary"] .alias
body .one-dizio[data-dizio-name="main-it"] .alias,
body .one-dizio[data-dizio-name="main-fr"] .alias,
body .one-dizio[data-dizio-name="main-en"] .alias,
body .one-dizio[data-dizio-name="main-it"] .alias *,
body .one-dizio[data-dizio-name="main-fr"] .alias *,
body .one-dizio[data-dizio-name="main-en"] .alias *{
    padding-top: 1px !important;
    font-family: Kingsoft Phonetic Plain, Tahoma, sans-serif !important;
}

#translate_button{
    display: inline-block;
    padding-top: 20px;
}

#content{
    padding-bottom: 40px;
}
#content > div,
#content > section > div{
    margin:0px;
    padding: 0px;
    overflow: hidden;
    background-color: white;
}
#content > div.Preview{
    border-top: 1px solid #ededed;
    padding: 12px 16px;
}
#content > div.Preview #Main{
    margin: -12px -16px;
    margin-top: 8px;
}

#content > div.Preview:first-child{
    border-top: none;
}

#content > div.Preview.multiple-language{
    position: relative;
}
#content > div.Preview.multiple-language:before{
    content: '';
    position: absolute;
    width: 24px;
    height: 16px;
    top: 20px;
    right: 18px;
    border-radius: 25px;
    /* background-image: url(assets/icons/flags/all.png); */
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.9;
}
#content > div.Preview.multiple-language[data-dizio-name="main-it"]:before{
    background-image: url('../../assets/flags/it.svg');
}
#content > div.Preview.multiple-language[data-dizio-name="main-fr"]:before{
    background-image: url('../../assets/flags/fr.svg');
}
#content > div.Preview.multiple-language[data-dizio-name="main-en"]:before{
    background-image: url('../../assets/flags/en.svg');
}

#content .addition{
    margin: 8px;
    background-color: #ffc10733;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-left: 3px solid #FFC107;
}

#content .one-dizio{
    position: relative;
    margin: 10px;
    border: none !important;
    border-radius: 12px;
}

#content #appuntoDiv{
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    background-color: whitesmoke;
    border-radius: 0px;
    color: #595959;
    margin: 10px 8px 20px;
    font-size: 1rem;
    font-weight: 800;
    border: none;
    border-left: 4px solid #40CC87;
}

div,
DIV{
    width: auto;
}

input{
    -webkit-appearance: none;
    outline: none;
    font-family: inherit !important;
}
a{
    padding-bottom: 1px;
    border-bottom: 2px dotted rgba(186, 186, 186, 0.64);
    text-decoration: none;
    color:#484848;
}
b{
    font-weight: 800;
}
u{
    text-decoration: none;
}
hr{
    opacity: 0.1;
}
mark{
    background: #4cffa7 !important;
    border-radius: 2px;
    padding: 0px 2px;
    margin: 0px -2px;
}

/*🍋组件*/

/* 少内容模式, 单一卡片 */
.Xpanding{
    box-shadow: none;
    margin: 0px;
    padding-top: 0px;
    margin-top: -6px;
}
.Xpanding .functionButton{
    display: none;
}
.Xpanding .checkerDIV{
    display: block;
}
.Hidden,
.hidden{
    display: none !important;
}

#SingleCard{
    padding-top:10px;
}


/* 🍓功能按钮 */
.functionButton{
    position: relative;
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    top: 0px;
    right: 0px;
    left: 0px;
    padding: 0px 10px;
    text-align: left;
    background-color: white;
    font-size: 1.2rem;
    color: #292929;
    border: none;
    font-weight: 800;
    border-radius: 0px;
}
.one-dizio .functionButton:after{
    content: '';
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 10px;
    top: 8px;
    background-image: url('../../assets/icons/icon/standard_arrow_right.png');
    background-size: contain;
    filter: brightness(0.5);
    transform: rotate(90deg);
    /*transition :all 0.2s linear;*/
}
.one-dizio.expanding .functionButton:after{
    transform: rotate(270deg);
}

.Preview .functionButton{
    border-top: 1px solid #f5f5f5;
}

.one-dizio.expanding .functionButton{
    border: 3px solid white !important;
    padding-left: 7px;
    background-color: whitesmoke;
    border-radius: 11px 11px 5px 5px;
}


.Xpanding:after{
    content: "";
}
#SingleCard:after{
    content: "";
}

/* 🍓展开控制组 */
.checkerDIV{
    height: 0px;
    overflow: hidden;
    margin: 0px;
}
.expanding .checkerDIV{
    height: auto !important;
    padding: 10px;
}

.checkerDIV > *{
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.checkerDIV>br:first-child,.checkerDIV>b>br:first-child{
    display: none;
}/* 隐藏头部空格 */

/*body>div,*/
/*.checkerIMG,*/
/*.checkerDIV{*/
/*-moz-transition :all 0.1s linear;*/
/*-o-transition :all 0.1s linear;*/
/*-webkit-transition :all 0.1s linear;*/
/*-ms-transition :all 0.1s linear;*/
/*transition :all 0.1s linear;*/
/*}*/

/* 🍋正文显示 */

#theIndice{
    background-color: white;
    margin: 0px;
    margin-bottom: 5px;
    line-height: 30px;
    font-size: 1.8rem;
    font-weight: 800;
    color: #3E3E3E;
    text-align: left;
}
#theIndice.Fast{
    font-size: 1.4em;
    line-height: 1.5em;
    font-weight: 600;
}

#theKeys{
    position: relative;
    top: -6px;
    margin: 10px 0px 0px;
    background-color: white;
    font-weight: 400;
    color:rgb(95, 95, 95) !important;
    font-size: 1.2rem;
    text-align: left;
}
#theKeys *{
    color:rgb(95, 95, 95) !important;
    font-weight: 400;
    font-size: 1.2rem;
}

.alias{
    margin: 0px;
    margin-top: 12px;
    margin-bottom: 10px;
    padding: 0px 22px 0px 10px;
    display: inline-block;
    position: relative;
    top: -10px;
    left: -5px;
    background-color: rgba(0, 0, 0, 0.02);
    color: rgb(72 72 72);
    /* font-size: 0.8rem; */
    border-radius: 20px;
    font-weight: 600;
}
.alias:after{
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/icons/SearchDetail/autoplay_voice.png');
    background-size: contain;
    position: absolute;
    top: 52%;
    transform: translateY(-55%) rotate(15deg);
    filter: grayscale(0.2);
}

.sommario{
    list-style: none;
    background-color: white;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-top: -10px;
    margin-bottom: 0px;
}
.sommarioProperty{
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 10px;
}

.sommarioProperty .property-note-indicator,
.property-note
{
    font-size: 0.8rem;
    font-weight: 400;
    background-color: whitesmoke;
    padding: 5px !important;
    margin-bottom: 5px;
    margin-right: 18px;
    margin-left: -4px;
    line-height: 1.5rem;
}
.sommarioProperty .property-note-indicator{
    display: inline-block;
    /*border-left: 4px solid #58D0A8; */
}
.property-note{
    display: block;
    background-color: whitesmoke;
    border-radius: 3px;
}

.sommarioProperty li{
    margin-bottom: 5px;
}

.sommarioProperty li,
.sommarioProperty li *{
    color:rgb(95, 95, 95) !important;
    font-weight: 400;
    font-size: 1.0rem;
}
.sommarioProperty>li:first-child{margin-top: 0px;}

.property{
    background-color: #58D0A8 !important;
    padding: 1px 6px 1px 6px !important;
    color: #424242;
    border-radius: 3px;
    margin: 15px 0px 8px;
    display: inline-block;
}

.riflessivo{
    /*border-left: 4px solid #626263 !important; */
}
.avv{
    /*border-left: 4px solid #545454 !important; */
}


.oneMean{
    display: block;
    margin-bottom: 5px;
    margin-top: 1.2rem;
}

.oneMean,
.oneMean span{
    position: relative;
    color: #000000;
    font-size: 1.1rem;
    font-weight: 800;
}

.underline .oneMean span{
    z-index: 1;
}
.underline .oneMean span:after{
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 5px;
    border-radius: 10px;
    background-color: rgb(88, 208, 168);
    z-index: -1;
}

/* 🍋Target相关 */
.sommarioProperty li{cursor: pointer;}
.sommarioProperty a{pointer-events: none;}
.Targeted span{
    padding-left: 5px;
    position: relative;
    background-color: rgba(88, 208, 168, 0.5);
}

.modeDire{
    display: block;
    border-left: 5px solid #bdaf9e;
    padding: 0px 10px;
    margin: 5px 10px;
}

.oneFrase{
    display: block;
    font-size: 1rem;
    color: #5c5c5c;
    line-height: 1.5rem;
    margin-bottom: 0.8rem;
}
.oneFrase span{
    margin-right: 0.5rem;
}


/* 🍋笔记 */
#appuntoDiv:after{
    content: "";
    color: #8299ca;
    font-size: 1rem;
    top:10px;
    right: 15px;
    font-weight: 400;
}


/*🍋变位表*/
.Origintable.it{
    position:relative;
    line-height: 1.8rem;
    font-size: 1rem;
    color: #5c5c5c;
}
.Origintable.it .IndiceTitle{
    position: relative;
    display: inline-block;
    min-width: 8em;
    color: #1d1d1d !important;
    padding: 0px 12px !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 800;
}
.Origintable.it .IndiceTitle::after{
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 165, 149, 0.8);
    display: block;
    width: 6px;
    height: 18px;
    border-radius: 10px;
}
.Origintable.it .IndiceTitle span{
    font-size: 13px;
    margin-left: 6px;
    margin-right: 2px;
    opacity: 0.6;
}
.Origintable.it .row{
    display: block;
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: whitesmoke;
    border-radius: 12px;
}
.Origintable.it .group{
    display: flex;
}
.Origintable.it .person{
    width: 33.33%;
    display: inline-block;
    border: 1px solid white;
    box-sizing: border-box;
    padding: 5px 8px;
}

.ke-zeroborder{display: none;}

.checkerDIV .SectionTitle{
    color: white !important;
    display: block;
    margin-top: 15px !important;
    margin-bottom: 5px;
    background-color: #4980c7 !important;
    border-radius: 5px;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}
.SectionTitle b{color:white;}
.SectionTitle br{display: none;}
.SectionTitle span{
    float: right;
    font-size: smaller;
    font-weight: 800;
    color:white;
}

[size ~= "5"] {
    display: none;
}


/*🍋近反义词 */
.SemDiv{
    position:relative;
    font-size: 1.2rem;
}
.SemDiv a{
    position: relative;
    font-size: 1rem;
    font-weight: 200;
}
.SemDiv b{
    color: transparent;
    background-color: #58D0A8;
    display: inline-block;
    border-radius: 3px;
    font-size: 6px;
    transform: scale(0.8, 0.6) translateY(-25%);
}
.anto{
    background-color: #bf637d !important;
}

/*好像没啥用*/
.sremi{
    color: white !important;
    display: block;
    margin-top: 15px !important;
    margin-bottom: 5px;
    background-color:#94a85f !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    height: 1rem;
    line-height: 1rem;
}
.sremi span{
    float: right;
    font-size: smaller;
    font-weight: 800;
    color:white;
}
.sremi+br{display: none;}
.sremi b{font-size:1rem;}


#Raw{
    padding: 10px 0px !important;
    padding-bottom: 20px;
    font-size: 1rem;

}
#Raw,
#Raw *{
    color: #565656 !important;
}
#searchdetail-content .property_key{
    font-size: 1.2rem !important;
    font-weight: 800 !important;
    color: #28c591 !important;
    margin-top: 8px;

    /*background-color: #58D0A8 !important;*/
    /*padding: 1px 6px 1px 6px !important;*/
    /*color: #424242;*/
    /*border-radius: 3px;*/
    /*margin: 15px 0px 8px;*/
    /*display: inline-block;*/
}
#searchdetail-content .property_key:first-child{
    margin-top: 0px;
}





/*🍉动词搭配/近义词 显示*/
font[color=blue]{
               color: #0068da !important;
           }
font[color="#0000FF"]{
    color: #006bdf !important;
}
font[color=green]{
    color: #00968b !important;
}
font[color=red]{
    color: #ff4c11 !important
}
font[color=yellow]{
    color: #ffc826 !important;
}
span[style='font-size:smaller;']{
    opacity: 0;
    display: block;
    height: 0px;
}

font[size='+1.5;']{
    font-size: 1.2em;
    color: #2b2b2b;
}

hr{
    background-color: black !important;
    color: black !important;
    border-color: black !important;
}

/*esempio block*/
span[style='display:block;background-color:rgb(236,245,221);']{
    position: relative;
    margin-top: 10px;
    border-radius: 3px;
    padding: 6px 8px !important;
    background-color: whitesmoke !important;
}
span[style='display:block;background-color:rgb(236,245,221);']>span:first-child{
    color: transparent !important;
    position: relative;
    background-color: transparent !important;
}
span[style='display:block;background-color:rgb(236,245,221);']>span:first-child>div{
    opacity: 0;
}
/*div[style='display:none;']{*/
/*display: block !important;*/
/*}*/
span[style='display:block;background-color:rgb(236,245,221);']>span:first-child:after{
    content: '查看例句';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    color: #2d2d26;
    pointer-events: none;
}


/*🍉SettingsPanel*/
    
/*🍋CoverImage*/
#cover_image_div{
        position: relative;
        background-color: whitesmoke;
        border-radius: 5px;
        overflow: hidden;
        z-index: 10;
        box-shadow: none;
        border: none;
    }
#cover_image_div img{
    width: 100%;
    height: auto;
}

/*🍋Settings*/
#settings_div{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: transparent;
    z-index: 1000;
}
#settings_div .blur-effect-panel{
    position: fixed;
    top: 52px;
    right: 10px;
    width: 200px;
    border-radius: 10px;
    max-height: 65vh;
    overflow-y: scroll;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-overflow-scrolling: touch;
    font-family: sans-serif;
}
#settings_div::-webkit-scrollbar{
    display: none;
}

#settings_div .close{
    position: absolute;
    top: 10px;
    right: 18px;
    padding: 5px 20px;

    background-color: #f5f5f5;
    color: #313131;
    border-radius: 20px;

    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

#settings_div section{
    margin: 12px 16px;
}
#settings_div section:first-child{
    margin-top: 0px;
}
#settings_div section:last-child{
    margin-bottom: 0px;
}

#settings_div .title{
    font-size: 12px;
    color: #5d5d5d;
    background-color: #f1f1f1;
    margin: 0px -16px;
    margin-bottom: -1px;
    padding: 6px 15px;
    font-weight: 600;
}
#settings_div section.panel .title-button{
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    background: whitesmoke;
    padding: 10px;
    border-radius: 8px;
}

#settings_div .option{
    position: relative;
    opacity: 0.8;
    line-height: 40px;
    border-top: 1px solid whitesmoke;

    font-family: sans-serif !important;
    font-weight: 400;
    font-size: 14px;
}
#settings_div .option.active:after{
    content: '●';
    position: absolute;
    right: 5px;
    top: 0px;
    font-size: 20px;
    font-weight: bold !important;
    opacity: 0.8;
}

/*大小*/
#settings_div .option[title='10px']{
    font-size: 10px !important;
}
#settings_div .option[title='12px']{
    font-size: 12px !important;
}
#settings_div .option[title='14px']{
    font-size: 14px !important;
}
#settings_div .option[title='16px']{
    font-size: 16px !important;
}
#settings_div .option[title='18px']{
    font-size: 18px !important;
}

/*粗细*/
#settings_div .option[title='200']{
    font-weight: 200 !important;
}
#settings_div .option[title='400']{
    font-weight: 400 !important;
}
#settings_div .option[title='800']{
    font-weight: 800 !important;
}

/*字体*/
#settings_div .option[title='serif']{
    font-family: serif !important;
}
#settings_div .option[title='sans-serif']{
    font-family: sans-serif !important;
}
#settings_div .option[title='monospace']{
    font-family: monospace !important;
}
#settings_div .option[title='fantasy']{
    font-family: fantasy, 'SnellRoundhand', serif !important; /*'SnellRoundhand'*/
}



/*🍋content*/
#searchdetail-content{
    position: relative;
    flex-grow: 1;
    padding-top: 50px;
    overflow-y: scroll;
}

/*mask*/
#empty-mask{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: 0;
}
#empty-mask > img{
    width: 75%;
    position: relative;
    top: -10%;
}

/*disabled*/
.disabled .icon,
.disabled .title{
    opacity: 0.35;
}
.disabled{
    position: relative;
}
.disabled:hover:after{
    content: '正在开发';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6em;
    font-size: 10px;
    background-color: #ffd950;
    color: #151515;
    text-align: center;
    border-radius: 6px;
    font-weight: 600;
}

/*pop-menu*/
#highlight_menu {
    font-family: 'Open Sans', Sans;
    font-weight: 100;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    background: rgba(0,0,0,.9);
    position: fixed;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    z-index: 100;
}

#highlight_menu.highlight_menu_animate {
    transition: top 75ms ease-out, left 75ms ease-out;
}

#highlight_menu .caret {
    border-style: solid;
    border-width: 10px 10px 0px 10px;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-color: rgba(0,0,0,.9);
    border-right-color: transparent;
    width: 0px;
    height: 0px;
    display: block;
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
}

#highlight_menu .items-wrapper {
    display: flex;
    width: 100%;
    padding:0;
    margin:0px;
}
#highlight_menu .items-wrapper li{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 10px 20px 0px;
}
#highlight_menu .items-wrapper li{
    font-size: 10px;
}
#highlight_menu .items-wrapper li i{
    font-size: 18px;
    margin-bottom: 2px;
}

#modify-editor-wrapper{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 100;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
#modify-editor-wrapper .nav-bar{
    display: flex;
    justify-content: space-between;
    padding: 10px 2px;
    margin-bottom: 10px;
    font-family: sans-serif;
    opacity: 0.7;
    cursor: pointer;
}
#modify-editor-wrapper textarea{
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 1.5em;
    flex-grow: 1;
}

.modified-content{
    position: relative;
    background-color: #ffeb00;
    padding: 0px 4px;
    margin: 0px 0px 0px 0px !important;
    border-radius: 4px;
    cursor: pointer;
}
.original-content{
    /* position: absolute; */
    top: 100%;
    left: 0px;
    /* background-color: rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); */
    border-radius: 5px;
    /* padding: 5px 10px; */
    margin-left: 10px;
    font-size: 12px;
    font-family: sans-serif;
    line-height: 1.5em;
    pointer-events: none;
    z-index: 100;
    opacity: 0.5;
}

#payment-info{
    text-align: center;
    background-color: whitesmoke !important;
    border-radius: 20px;
    padding: 20px !important;
    margin: 10px !important;
    line-height: 2em;
}
.purchase_button{
    text-align: center;
    text-decoration: none;
    color: white !important;
    background-color: #2182F7;
    line-height: 40px;
    border-radius: 20px;
    margin-bottom: 12px;
    margin-top: 8px;
    padding: 5px 12px;
    border: none;
}


/*🍋online-dict*/
.frameContainer{
    padding: 0px !important;
}
.online-dict-frame{
    border: none;
    width: 100%;
    min-height: 50vh;
}


/*🍋navbar*/
.SearchDetail ion-header{
    position: absolute;
    z-index: 100;
    backdrop-filter: blur(30px) brightness(100%);
    background-color: #f5f5f57a;
}
.SearchDetail ion-toolbar{
    --border-width: 0 !important;
    --border-color: #00000005;
}

.SearchDetail ion-toolbar,
.SearchDetail ion-content{
    --background: transparent;
    /*--background: #54596b;*/
}
.SearchDetail ion-toolbar,
.SearchDetail ion-toolbar *{
    color: #6b6b6b !important;
}
.SearchDetail ion-toolbar ion-buttons{
    display: flex;
    justify-content: space-between;
    padding: 0px 6px;
}

.SearchDetail ion-toolbar .nav-item{
    position: relative;
    width: 42px;
    height: 40px;
    /*filter: grayscale(1) brightness(10);*/
}

.SearchDetail ion-toolbar .nav-item .btn,
.SearchDetail ion-toolbar .nav-item .icon {
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.SearchDetail ion-toolbar .lists{
    max-height: 60vh;
    overflow-y: scroll;
}
.SearchDetail ion-toolbar .list{
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.SearchDetail ion-toolbar .list i{
    font-size: 12px;
    opacity: 0.65;
}

/*CardMode*/
fragment-searchdetail .flex-wrapper{
    background-color: white !important;
}

fragment-searchdetail ion-toolbar,
fragment-searchdetail .flex-wrapper{
    padding-top: 0px !important;
}

fragment-searchdetail ion-back-button{
    display: none !important;
}
fragment-searchdetail .SearchDetail{
    border-radius: 12px;
    overflow: hidden;
}
fragment-searchdetail #content .one-dizio{
    margin: 0px !important;
    border-radius: 0px;
    border-bottom: 10px solid whitesmoke !important;
}
fragment-searchdetail #content .one-dizio:last-child{
    border-bottom: none !important;
}
fragment-searchdetail #content .one-dizio:first-child{
    border-radius: 10px 10px 0px 0px !important;
}

/*🍋menu*/
.SearchDetail .dropdown-menu.lists{
    display: block;
    top: 52px;
    left: 10px;
}