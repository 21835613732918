custom-listviewcontroller ion-header,
.ListViewController ion-header{
    --background: white !important;
}
custom-listviewcontroller ion-content,
.ListViewController ion-content{
    --background: whitesmoke !important;
}

.icons-hidden{
    display: none;
}

.ListViewController .buttons-group{
    position: relative;
    top:-2px;
}
.ListViewController .buttons-group .button{
    font-size: 13px !important;
}

/*🍋lists*/
.listview-wrapper{
    padding: 12px;
}
.listview-wrapper.dragging:after{
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    pointer-events: none;
    background-color: rgba(22, 239, 178, 0.4);
    z-index: 100;
}

.listview-wrapper .section-clear{
    margin-bottom: 25px;
}

.listview-wrapper .section-title{
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 6px;
    padding: 0px 2px;
}
.listview-wrapper .section-title span{
    opacity: 0.35;
}
.listview-wrapper .section-title .button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 800;
}
.listview-wrapper .section-title .button ion-icon{
    font-size: 16px;
    margin-right: 5px;
}

.listview-wrapper .list{
    position: relative;
    padding: 0px;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 6px;
    transition: all 0.1s ease-out;
    touch-action: auto !important;
}
.listview-wrapper .list .list-content{
    position: relative;
    padding: 12px 16px;
    overflow: hidden;
}

.listview-wrapper .list:hover{
    cursor: pointer;
    /*left: 3px;*/
    /*transform: scale(1.02);*/
}
.listview-wrapper .list.active{
    box-shadow: -4px 0px 0px #4fca91;
    margin-left: 4px;
}

.listview-wrapper .list .list-name-group{
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}
.listview-wrapper .list .list_name{
    font-size: 14px;
    font-weight: 400;
    opacity: 0.85;
}
.listview-wrapper .list .badge{
    position: relative;
    top: 0px;
    padding: 5px 8px;
    margin-left: 8px;
    min-width: 3em;
    background-color: whitesmoke;
    /*overflow: hidden;*/
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 9px;
    text-shadow: none;
}
.listview-wrapper .list .list_num{
    position: relative;
    color: gray;
    font-size: 9px;
    z-index: 1;
    top: 0px;
    font-weight: 400;
}
.listview-wrapper .list .memo_stack{
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 3px;
    bottom: -5px;
    /* top: 45px; */
    left: 2px;
    right: 2px;
    border-radius: 10px;
    overflow: hidden;
    opacity: 0.85;
    background-color: #d8d8d8;
}
.listview-wrapper .list .memo_stack .stack_item[title='memo_untracked']{
    background-color: whitesmoke !important;
}

.listview-wrapper .list .arrow{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    opacity: 0.3;
}

.studylists-wrapper .list .memo_stack .stack_item.has_value{
    min-width: 2px;
    border-right: 1px solid white;
}
.studylists-wrapper .list .memo_stack .stack_item.has_value:last-child{
    border-right: none;
}
.list .timestamp{
    font-size: 10px;
    opacity: 0.3;
}
.list .actions-wrapper{
    position: absolute;
    top: 50%;
    right: 25px;
    text-align: center;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
.list .actions-wrapper .action{
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    /*background-color: whitesmoke;*/
    /*margin-left: 8px;*/
}
.list .actions-wrapper .action:hover{
    opacity: 0.75;
}

.list .actions-wrapper .action > ion-icon{
    font-size: 20px;
    color: #d0d0d0;
}
.list .actions-wrapper .action-heart > ion-icon{
    color: #ff5542;
}
.list .actions-wrapper .action-heart{
    display: none;
}
.list.primary .actions-wrapper .action-heart{
    display: flex;
}

.list .actions-wrapper .action-menu > ion-icon{
    font-size: 18px;
}

.list .list_menu{
    position: absolute;
    top: 40px;
    right: 5px;
}

.studybook-cover{
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 5;
}

.studybook-cover .list_name{
    font-size: 16px;
}

.studybook-cover .button{
    padding: 3px 10px;
    margin-left: 5px;
    border-radius: 20px;
    font-size: 11px;
    font-weight: 600;
    color: #a0a0a0;
    border: 1px solid #f1f1f1;
    opacity: 0;
}
.studybook-cover .arrow:before{
    display: inline-block;
}


.studybook-cover.empty + .studychapters-wrapper{
    display: none !important;
}
.studybook-cover.empty .actions-wrapper,
.studybook-cover.empty .badge,
.studybook-cover.empty .timestamp,
.studybook-cover.empty .arrow{
    display: none;
}
.studybook-cover.empty .list-name-group{
    margin-bottom: 0px;
}

/*folded*/
/*.studybook-cover.folded{*/
/*box-shadow: none;*/
/*}*/
/*.studybook-cover .button-fold:before{*/
/*pointer-events: none;*/
/*content: '收起';*/
/*}*/
/*.studybook-cover.folded .button-fold:before{*/
/*content: '展开';*/
/*}*/
/*.studybook-cover .arrow:before{*/
/*transform: rotate(180deg);*/
/*}*/
/*.studybook-cover.folded .arrow:before{*/
/*transform: rotate(0deg);*/
/*}*/
/*.studybook-cover.folded + .studychapters-wrapper{*/
/*display: none;*/
/*}*/

.studychapters-wrapper{
    border-radius: 0px 0px 10px 10px;
    /*background-color: rgba(0, 0, 0, 0.05);*/
    /* padding: 0px 8px; */
    overflow: hidden;
    margin: 0px 5px;
    margin-top: -10px;
    margin-bottom: 25px;
    border-radius: 3px 3px 15px 15px;
    overflow: hidden;
    z-index: 1;
}
/*.studychapters-wrapper .list:first-child{*/
/*border-radius: 2px 2px 6px 6px;*/
/*padding-top: 22px;*/
/*}*/
.studychapters-wrapper .list{
    margin: 0px;
    border-bottom: 1px dashed whitesmoke;
}
.studychapters-wrapper .list:last-child{
    border-bottom: none;
}
.studychapters-wrapper .timestamp{
    display: none;
}

/*横向滑动*/
.studychapters-wrapper{
    position: relative;
    top: -5px;
    padding: 10px 15px;
    margin: 0px -5px !important;
    z-index: 10;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.studychapters-wrapper::-webkit-scrollbar{
    display: none;
}
.studychapters-wrapper > .list{
    display: inline-block;
    box-sizing: border-box;
    width: auto;
    border: 1px solid whitesmoke !important;
    min-width: 50px;
    margin-right: 6px !important;
}
.studychapters-wrapper > .list .list-content{
    padding: 5px 5px 10px !important;
}
.studychapters-wrapper > .list:after{
    display: none;
}
.studychapters-wrapper > .list .actions-wrapper{
    right: 50%;
    bottom: -18px;
    top: unset;
    z-index: 10;
    transform: translateX(50%);
}
.studychapters-wrapper > .list .list-name-group{
    flex-direction: column;
}
.studychapters-wrapper > .list .list-name-group .badge{
    margin: 0px !important;
}

.studychapters-wrapper > .list .arrow{
    display: none;
}

.MODE-CHOOSE-PRIMARY-LIST{
    /*padding: 25px 0px;*/
}
.MODE-CHOOSE-PRIMARY-LIST .section-title span{
    font-size: 13px;
}
.MODE-CHOOSE-PRIMARY-LIST .arrow{
    display: none;
}
.MODE-CHOOSE-PRIMARY-LIST .actions-wrapper{
    right: 15px;
}