.iframe-wrapper.fullscreen{
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 0px;
    height: 100%;
    border: none;
    background-color: white;
}

.iframe-wrapper.loading{
    background-image: url("../../assets/loading.gif");
    background-position: center;
    background-size: 36px;
    background-repeat: no-repeat;
    filter: grayscale(1);
}