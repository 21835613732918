.WordsRichTable{
    position: absolute;
    top: 50px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}
.WordsRichTable .tableview-header{
    padding: 10px 0px;
    border-bottom: 1px solid #d8d8d8;
}
.WordsRichTable .tableview-cells-wrapper{
    flex-grow: 1;
}


.WordsRichTable .ui-tableview-cell{
    padding: 0px !important;
}
.WordsRichTable .cell-content{
    display: flex;
    align-items: center;
    height: 100%;
}
.WordsRichTable .cell-content .col{
    white-space: nowrap;
    overflow-x: scroll;
}
.WordsRichTable .cell-content .col.order,
.WordsRichTable .cell-content .col.language{
    flex-grow: 0;
    min-width: 4em !important;
    text-align: center;
}
.WordsRichTable .cell-content .col::-webkit-scrollbar{
    display: none;
}

.WordsRichTable .cell-content .col-text[status='waiting']{
    background-color: #f7f7f7;
}
.WordsRichTable .cell-content .col-text[status='loading']{
    background-color: #ffe8e8;
}
.WordsRichTable .cell-content .col-text[status='loaded']{
    background-color: #daffef;
}