.page-study ion-content{
    background-color: whitesmoke;
    --background:whitesmoke;
}
.page-study .section-description{
    position: relative;
    top: -11px;
    left: -1px;
}

.section.section-today{
    margin-bottom: 0px;
    padding-bottom: 5px;
}

/*🍋sync*/
.action.sync{
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    top: 3px;
    opacity: 0.8;
}
.action.sync .icon{

}
.action.sync.ing .icon{
    animation: rotation 2s infinite linear;
    opacity: 0.5;
    pointer-events: none;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

/*🍋progress bar*/
.section-repo .buttons-group{
    position: relative;
    top: -1px;
}

.books-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 3px;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.books-wrapper > *{
    display: flex;
    align-items: center;
}
.books-wrapper ion-icon{
    opacity: 0.6;
}

.books-wrapper .label{
    margin:0px 10px 0px 3px;
    opacity: 0.7;
    opacity: 1;
}
.books-wrapper .total{
    position: absolute;
    right: 3px;
    opacity: 0.5;
}

#top_bar{
    position: relative;
    z-index: 10;
    padding: 40px 10px 20px;
    margin-bottom: 30px;
}
#top_bar .sfondo{
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    z-index: -1;
    transform: rotate(-3deg) translateY(-20px);
    background-image: linear-gradient(to top, #00b0d8 -50%, #17cca2 50%);
}

/*统计图*/
.dropdown-item.memo-item:before{
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -6px;
}

.dropdown-item.memo-item[title='memo_known']:before,
#chart_wrapper .infos_div .info .sub_info.memo_known:after,
.bars .memo_known{
    background-color: #4083ff;
}

.dropdown-item.memo-item[title='memo_familiar']:before,
#chart_wrapper .infos_div .info .sub_info.memo_familiar:after,

.bars .memo_familiar{
    background-color: #57e2b9;
}

.dropdown-item.memo-item[title='memo_danger']:before,
#chart_wrapper .infos_div .info .sub_info.memo_danger:after,

.bars .memo_danger{
    background-color: #ff5c41;
}

.dropdown-item.memo-item[title='memo_untracked']:before,
#chart_wrapper .infos_div .info .sub_info.memo_untracked:after,
.bars .memo_untracked{
    background-color: #D1D6DE;
}

#chart_wrapper{
    position: relative;
    overflow: hidden;
}

#chart_wrapper .infos_div{
    margin: 0px 0px 12px;
}
#chart_wrapper .infos_div .info{
    background-color: rgba(0, 0, 0, 0.05);
    text-align: center;
    color: #373737;
}
#chart_wrapper .infos_div .info:last-child{
    background-color: transparent;
    padding: 2px 0px;
}

#chart_wrapper .infos_div .info .num{
    z-index: 2;
}
#chart_wrapper .infos_div .info .sub_info{
    position: relative;
    font-size: 9px;
    /*background-color: rgba(0, 0, 0, 0.05);*/
    padding: 2px 8px;
    padding-left: 15px;
    margin-left: 5px;
    border-radius: 10px;
}
#chart_wrapper .infos_div .info .sub_info:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: gray;
    margin-top: -3px;
}
#chart_wrapper .infos_div .info .memo_num{
    background-color: transparent;
    font-size: 9px;
    color: rgba(0, 0, 0, 0.5);
}


.bars-wrapper{
    position: relative;
    font-size: 8px;
    height: 18px;
    border-radius: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.03);
}
.bars {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    border-radius: 10px !important;
    padding: 5px;
    overflow: hidden;
    z-index: 1;
}
.bars > div {
    min-width: 1px;
    margin-right: 2px;
    border-radius: 1px;
}
.bars > div:last-child{
    border-radius:3px 10px 10px 3px;
}
.bars > div:first-child{
    border-radius: 10px 3px 3px 10px;
}
.bars .memo_start{
    z-index: 1;
}
.bars .memo_known{
    z-index: 2;
}
.bars .memo_familiar{
    z-index: 3;
}

/*cell*/
/*#chart_wrapper .cell{*/
/*display: flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*padding: 10px 0px 0px 3px;*/
/*border-top: 1px solid whitesmoke;*/
/*margin-top: 22px;*/
/*font-size: 14px;*/
/*font-weight: 600;*/
/*}*/
.section-repo .cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 15px;
    /* margin: -5px -8px; */
    margin-top: 8px;
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    border-radius: 10px;
}

.section-repo .cell > *{
    opacity: 0.75;
}
.section-repo .cell ion-icon{
    font-size: 17px;
    margin-left: 2px;
    opacity: 0.2;
}

/*用户信息*/
#top_bar #user_info_div{
    display: inline-block;
    padding: 5px 8px 5px 12px;
    margin-bottom: 10px;
    border-radius: 5px;
}
#top_bar #user_info_div .user_email{
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    display: inline-block;
}
#top_bar #user_info_div .login_icon{
    width: 20px;
    float: right;
    margin-left: 2px;
    filter: brightness(10);
}

/*菜单按钮*/
#top_bar #menu_button{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    position: absolute;
    top: 20px;
    right: 20px;

    display: none;
}

/*树状图按钮*/
#top_bar #tree-button{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    bottom: -12px;
    right: 10px;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

    /*display: none;*/
}
#top_bar #tree-button:hover{
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

/*🍋primarylist*/
.primary-list-wrapper{
    display: flex;
    align-items: center;
}
.primary-list-wrapper ion-icon{
    opacity: 1;
    margin-left: 3px;
    color: #ff5542;
}

/*🍋numbers*/
.section-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*.section-title .label:after{*/
/*content: '';*/
/*position: absolute;*/
/*bottom: 0px;*/
/*left: 2px;*/
/*right: 3px;*/
/*height: 5px;*/
/*border-radius: 10px;*/
/*background-color: green;*/
/*}*/

.section-today .section-title{

}
.number-wrapper{
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0px 80px
}
.number-wrapper .number {
    display: flex;
    flex-direction: column;
    width: 10em;
    text-align: center;
}
.number-wrapper .number > span:first-child {
    font-size: 22px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
}
.number-wrapper .number > span:last-child {
    font-size: 13px;
    opacity: 0.5;
}
.number-wrapper .number .num{
    position: relative;
    right: -4px;
}
.number-wrapper .number .separator{
    opacity: 0.25;
    display: inline-block;
    margin: 0px 3px;
    transform: rotate(15deg);
    font-family: "Courier New","Courier", sans-serif;
}
.number-wrapper .number .target{
    font-size: 16px;
    opacity: 0.3;
    position: relative;
    bottom: -3px;
    right: 3px;
}

/*🍋listview*/

/* show/hide */
.page-study .listview-wrapper {
    /*height: 0px;*/
    display: none;
}
.page-study .all-lists-wrapper.expanding .listview-wrapper{
    display: block !important;
}
.page-study .all-lists-wrapper.expanding .cell{
    box-shadow: 0px 5px 15px #0000001a;
}
.page-study .all-lists-wrapper.expanding .cell ion-icon{
    transform: rotate(180deg);
}

.page-study .listview-wrapper {
    /* background-color: white; */
    padding: 0px 12px 0px 12px;
    /* margin-top: -5px; */
    border-radius: 9px;
    overflow: hidden;
    transition: all ease-in .2s;
}
.page-study .listview-wrapper .section-clear{
    margin-top: 8px;
    margin-bottom: 8px;
    overflow: hidden;
}
.page-study .listview-wrapper .section-clear:last-child{
    /*margin-bottom: -10px;*/
}

.page-study .listview-wrapper .section-clear .studylists-wrapper,
.page-study .listview-wrapper .section-clear .studybooks-wrapper{
    background-color: white;
    border-radius: 10px !important;
}


.page-study .listview-wrapper .section-title{
    position: relative;
    margin-bottom: 0px;
    padding: 0px 8px;
    /* background-color: whitesmoke; */
    height: 28px;
    /* border-radius: 5px; */
    /* margin-left: 9px; */
    /*border-bottom: 1px solid whitesmoke;*/
}
/*.page-study .listview-wrapper .section-title:after{*/
/*content: '';*/
/*position: absolute;*/
/*left: 0px;*/
/*top: 8px;*/
/*width: 4px;*/
/*height: 14px;*/
/*background-color: #d6d6d6;*/
/*border-radius: 10px;*/
/*}*/
.page-study .listview-wrapper .section-title span{
    font-size: 12px;
    opacity: 0.5;
}
.page-study .listview-wrapper .section-title .button{
    background-color: transparent;
    font-size: 12px;
    margin-right: -10px;
    display: none;
}


.page-study .listview-wrapper .studybook-cover{
    /*margin-top: 10px !important;*/
    box-shadow: none;
    /*border-bottom: 1px solid whitesmoke;*/
    /*border-radius: 0px;*/
}
.page-study .listview-wrapper .studybook-cover .list-content{
    padding: 15px 12px  15px !important;
}
.page-study .listview-wrapper .studybook-cover.folded{
    /*border-bottom: none;*/
    /*margin-top: 0px !important;*/
    /*padding: 10px 8px !important;*/
}
.page-study .listview-wrapper .studychapters-wrapper{
    top: -12px;
}

.page-study .listview-wrapper .list{
    margin: 0px;
}
.page-study .listview-wrapper .list .list-content{
    padding: 10px 12px;
}
.page-study .listview-wrapper .list:after{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    left: 10px;
    height: 1px;
    background-color: whitesmoke;
}
.page-study .listview-wrapper .list:first-child:after{
    display: none;
}

.page-study .listview-wrapper .list .list_name{
    font-weight: 600;
}

/*.page-study .listview-wrapper .studylists-wrapper .list{*/
/*border-bottom: 1px solid whitesmoke;*/
/*border-radius: 0px;*/
/*}*/
/*.page-study .listview-wrapper .studylists-wrapper .list:last-child{*/
/*border-bottom: none;*/
/*}*/


.page-study .listview-wrapper .studychapters-wrapper{
    margin: 0px;
    margin-top: -10px;
}



/*🍋fire*/
.section-fire{
    height: 100px;
    overflow: visible;
    transform: scale(0.5);
    pointer-events: none;
}
.section-fire .cf-container{
    top:-50px;
}
.section-fire .cf-log{
    box-shadow: none;
    background: #5f4947;
    transform: scaleY(0.7) translateY(10px) !important;
}

.section-fire .fire-words .cf-flame{
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-fire .fire-words .cf-flame span{
    color: white;
    font-size: 20px;
    overflow: hidden;
    padding: 5px;
}



