/*🍋block*/
.section{
    position: relative;
    padding: 10px 12px;
    margin-bottom: 10px;
}
.section-title{
    margin-bottom: 8px;
    padding-left: 3px;
    padding-right: 3px;
}
.section-description{
    font-size: 13px;
    color: gray;
    padding: 5px;
}
.section-title .label{
    position: relative;
    font-size: 22px;
    font-weight: 800;
    opacity: 0.85;
}
.section-content{
    padding: 12px 15px;
    background-color: white;
    border-radius: 12px;
}

/*🍋inline*/

/*color*/
.color-dark{
    color: rgb(45, 45, 45) !important;
}

/*buttons*/
.button.light{
    color: #313131;
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.buttons-group{
    display: flex;
    padding: 0px 3px;
    height: 26px;
}
.buttons-group *{
    font-size: 13px !important;
    color: #313131;
    font-weight: 600;
    background-color: transparent !important;
}
.buttons-group > *{
    background-color: rgba(0, 0, 0, 0.05) !important;

    border-radius: 3px !important;
    margin: 0px !important;
    margin-right: 2px !important;
}
.buttons-group > *:first-child{
    border-radius: 20px 3px 3px 20px !important;
}
.buttons-group > *:last-child{
    border-radius: 3px 20px 20px 3px !important;
    margin-right: 0px !important;
}

.buttons-group button,
.buttons-group .button{
    padding-left: 8px;
    padding-right: 8px;
}
.buttons-group .button{
    font-size: 15px !important;
    font-weight: 600;
}

/*icons*/
.menu-button .text{
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    opacity: 0.8;
}
.menu-button ion-icon{
    transform: scale(0.9) !important;
}
.share-button ion-icon{
    transform: scale(0.95) !important;
}

/*action-sheet*/
.action-sheet-sub-title.sc-ion-action-sheet-ios{
    padding-top: 5px !important;
}

.action-sheet-button{
    color: rgb(95, 95, 95) !important;
}
.action-sheet-button.action-sheet-destructive{
    color: #f51919 !important;
}
.action-sheet-button.disabled,
.action-sheet-button.action-sheet-destructive.disabled{
    color: #d0d0d0 !important;
    pointer-events: none;
}

/*tab*/
ion-tab-button ion-icon{
    transform: scale(0.8);
}
ion-tab-button[tab='tab_discover'] ion-icon{
    transform: scale(0.9);
}
ion-tab-button{
    --color-selected: rgb(30, 30, 30) !important;
}


/*🍋safearea*/
/**
 * SafeArea Support
 */
.safe-area-top{
    ﻿padding-top: constant(safe-area-inset-top);
    /* < iOS 11.2 */
    padding-top: env(safe-area-inset-top);
    /* >= iOS 11.2 */
}
.safe-area-bottom{
    ﻿padding-bottom: constant(safe-area-inset-bottom);
    /* < iOS 11.2 */
    padding-bottom: env(safe-area-inset-bottom);
    /* >= iOS 11.2 */
}

/*🍋components*/
.dropdown-menu{
    background-color: rgba(245, 245, 245, 0.95);
    border-radius: 10px;

    border: 1px solid #0000000d;
    box-shadow: 0px 8px 15px #00000021;

    z-index: 100;

    padding: 2px 0px;
    min-width: 180px;
    overflow-x: hidden;
    z-index: 10001;
}
@supports (backdrop-filter: blur(30px)){
    .dropdown-menu{
        backdrop-filter: blur(30px) brightness(110%);

        border: none;
        box-shadow: none;
    }
}

.dropdown-menu.for-bottom-bar{
    top: unset;
    bottom: 95px;
    bottom: calc(constant(safe-area-inset-bottom) + 95px);
    bottom: calc(env(safe-area-inset-bottom) + 95px);
    left: 50%;
    transform: translateX(-50%);
    max-height: 75vh;
    width: 90%;
    max-width: 320px;
}


.dropdown-menu .dropdown-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    border-bottom: 1px solid #00000012;
}
.dropdown-menu .dropdown-item.disabled{
    color: #bbbbbb;
}
.dropdown-menu .dropdown-item:last-child{
    border-bottom: none;
}
.dropdown-menu .dropdown-item .check{
    font-family: system-ui, sans-serif !important;
    font-weight: 600 !important;
}
.dropdown-menu .dropdown-item.checked:after{
    content: "✓";
    font-family: system-ui, sans-serif !important;
    font-weight: 600 !important;
    margin-left: 8px;
    position: absolute;
    right: 12px;
}

.mask{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.blur-effect-panel{
    background-color: rgba(245, 245, 245, 0.95);
    border-radius: 10px;

    border: 1px solid #0000000d;
    box-shadow: 0px 8px 15px #00000021;

    z-index: 100;
}
.blur-effect-panel.dark{
    filter: invert(1);
    background-color: white;
    backdrop-filter: unset !important;
    opacity: 0.9;
}

.blur-effect-panel.dark .switch{
    filter: invert(1.15);
}

@supports (backdrop-filter: blur(30px)){
    .blur-effect-panel{
        backdrop-filter: blur(30px) brightness(110%);

        border: none;
        box-shadow: none;
    }
    .blur-effect-panel.light{
        backdrop-filter: blur(30px) brightness(150%);
    }
}
