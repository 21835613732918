:host{

}

.ion-page{
    display: flex !important;
}
.ion-page.ion-page-hidden{
    display: none !important;
}






iframe-nav-detail *{
    overflow: hidden !important;
    --overflow: hidden !important;
    -webkit-overflow-scrolling: auto; /* 当手指从触摸屏上移开，滚动会立即停止 */
}
iframe-nav-detail .iframe-wrapper.fullscreen{
    /*top: 44px !important;*/
}

/*gesture-support*/
#frame-content{
    position: relative;
    height: 100%;
    overflow: hidden;
}
#gesture-handlr{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 16px;
    background-color: transparent !important;
    z-index: 10;
}

/*back-button*/
ion-back-button {
    --icon-font-size: 1.5em;
    --icon-margin-end: -3px;
    color: var(--ion-color-dark);
    position: relative;
    top: 0px;
}

/*right-button*/
.right-buttons ion-icon{
    color: black;
    transform: scale(0.95);
}